import { BigNumber, Contract } from "ethers";
import StreamManagerABI from "./abi/StreamManager.json";
import AdaptiveFlowErrorABI from "./abi/AdaptiveFlowsErrorABI.json";
import { Interface } from "ethers/lib/utils";
import { AbstractWeb3Service, BN_ZERO } from "./abstractWebService.js";
import { initStreamSDK, streamSDK } from "./useStreamSDK.js";
import { transactionProcessingStepStore } from "../../store.js";
import { PaymentProcessingSteps } from "../../shared/utils/utils.svelte";

/**
 * Flow Permissions
 */
export let ScheduleFlowPermissionLevel;
(function (ScheduleFlowPermissionLevel) {
  ScheduleFlowPermissionLevel[
    (ScheduleFlowPermissionLevel["CREATE_FLOW"] = 1)
  ] = "CREATE_FLOW";
  ScheduleFlowPermissionLevel[
    (ScheduleFlowPermissionLevel["UPDATE_FLOW"] = 2)
  ] = "UPDATE_FLOW";
  ScheduleFlowPermissionLevel[
    (ScheduleFlowPermissionLevel["CREATE_UPADTE_FLOW"] = 3)
  ] = "CREATE_UPADTE_FLOW";
  ScheduleFlowPermissionLevel[
    (ScheduleFlowPermissionLevel["DELETE_FLOW"] = 4)
  ] = "DELETE_FLOW";
  ScheduleFlowPermissionLevel[
    (ScheduleFlowPermissionLevel["CREATE_DELETE_FLOW"] = 5)
  ] = "CREATE_DELETE_FLOW";
  ScheduleFlowPermissionLevel[
    (ScheduleFlowPermissionLevel["DELETE_UPDATE_FLOW"] = 6)
  ] = "DELETE_UPDATE_FLOW";
  ScheduleFlowPermissionLevel[
    (ScheduleFlowPermissionLevel["CREATE_UPDATE_DELETE_FLOW"] = 7)
  ] = "CREATE_UPDATE_DELETE_FLOW";
})(ScheduleFlowPermissionLevel || (ScheduleFlowPermissionLevel = {}));

/**
 * Stream Service
 * required "@streamable-finance/sdk-core": "0.5.5-rc.7"
 */
export class StreamService extends AbstractWeb3Service {
  /**
   * Constructor
   * @param web3Provider
   * @param streamSDK
   */
  constructor(web3Provider, streamSDK) {
    super(web3Provider);
    this.streamSDK = streamSDK;
  }

  /**
   * Create Authorization Client->Merchant
   * @param params
   */
  async createAuthorization(params) {
    return await this.createAuthorizationAsSender(params);
  }

  /**
   * Create Authorization As a Sender
   * @param superTokenAddress
   * @param receiverAddress
   * @param maxFlowRate
   * @param maxDuration
   * @param maxAmount
   * @param minAmount
   * @param streamManagerAddress
   * @param gelatoFee
   * @private
   */
  async createAuthorizationAsSender({
    superTokenAddress,
    receiverAddress,
    maxFlowRate,
    maxDuration,
    maxAmount,
    minAmount = BN_ZERO,
    streamManagerAddress,
    gelatoFee,
  }) {
    const signer = this.web3Provider.getSigner();

    const streamManagerContract = new Contract(
      streamManagerAddress,
      StreamManagerABI,
      signer,
    );

    gelatoFee =
      minAmount instanceof BigNumber && !minAmount.isZero()
        ? gelatoFee.mul(2)
        : gelatoFee;

    try {
      const createAuthorizationRequest = await streamManagerContract
        .connect(signer)
        .createAuthorization(
          superTokenAddress,
          receiverAddress,
          maxFlowRate,
          maxDuration,
          maxAmount,
          minAmount,
          { value: gelatoFee },
        );

      return await this.parseTx(
        streamManagerContract,
        createAuthorizationRequest,
      );
    } catch (error) {
      transactionProcessingStepStore.set(null);
      return this.getTxErrorResponse(error);
    }
  }

  /**
   * Update Permission Flow if needed (Merchant will be able to create stream on client behalf)
   * @param superTokenAddress
   * @param streamManagerAddress
   */
  async updateFlowPermissions({ superTokenAddress, streamManagerAddress }) {
    try {
      const superToken = await this.getSuperToken(superTokenAddress);
      const signer = this.web3Provider.getSigner();
      const signerAddress = await signer.getAddress();

      const currentFlowPermission = await this.getFlowPermissions(
        superToken,
        signerAddress,
        streamManagerAddress,
        signer,
      );

      transactionProcessingStepStore.set(PaymentProcessingSteps.SEND_AUTH);

      if (
        Number(currentFlowPermission) >=
        ScheduleFlowPermissionLevel.CREATE_UPDATE_DELETE_FLOW
      ) {
        return { error: false };
      }

      const updateFlowPermissionsTx = await this.updateFlowPermissionsExec(
        superToken,
        streamManagerAddress,
        signer,
        true,
      );

      return { error: false, hash: updateFlowPermissionsTx.hash };
    } catch (error) {
      transactionProcessingStepStore.set(null);
      return this.getTxErrorResponse(error);
    }
  }

  /**
   * Check if Client should add allowance/revoke for streamManagerAddress
   * @param superToken
   * @param signerAddress
   * @param streamManagerAddress
   * @param signer
   * @private
   */
  async getFlowPermissions(
    superToken,
    signerAddress,
    streamManagerAddress,
    signer,
  ) {
    const { permissions } = await superToken.getFlowOperatorData({
      sender: signerAddress,
      flowOperator: streamManagerAddress,
      providerOrSigner: signer,
    });

    return Number(permissions);
  }

  /**
   * Execute Update Permission Flow (Merchant will be able to create stream on client behalf)
   * @param superToken
   * @param streamManagerAddress
   * @param signer
   * @param waitForTransactionApproval
   * @private
   */
  async updateFlowPermissionsExec(
    superToken,
    streamManagerAddress,
    signer,
    waitForTransactionApproval,
  ) {
    const gasPrice = await this.web3Provider.getGasPrice();
    const updateFlowPermissions =
      superToken.authorizeFlowOperatorWithFullControl({
        flowOperator: streamManagerAddress,
        overrides: {
          gasPrice: gasPrice,
        },
      });

    const updateFlowPermissionsTx = await updateFlowPermissions.exec(signer);
    if (waitForTransactionApproval) {
      await updateFlowPermissionsTx.wait();
    }

    return updateFlowPermissionsTx;
  }

  /**
   * Get tx error to friendly format and return
   * @param error
   * @private
   */
  getTxErrorResponse(error) {
    console.error(error);
    const adaptiveFlowErrorInterface = new Interface(AdaptiveFlowErrorABI);
    return this.parseTxError(error, adaptiveFlowErrorInterface);
  }

  /**
   * Get super token object
   * @param superTokenAddress
   * @private
   */
  async getSuperToken(superTokenAddress) {
    if (!this.streamSDK) {
      await initStreamSDK(this.web3Provider);
      this.streamSDK = streamSDK.instance;
    }

    return await this.streamSDK.loadSuperToken(superTokenAddress);
  }

  /**
   * Delete an existing Authorization As a Sender
   * @param superTokenAddress
   * @param receiverAddress
   * @param streamManagerAddress
   * @private
   */
  async deleteAuthorization({
    superTokenAddress,
    receiverAddress,
    streamManagerAddress,
  }) {
    const signer = this.web3Provider.getSigner();

    const streamManagerContract = new Contract(
      streamManagerAddress,
      StreamManagerABI,
      signer,
    );

    try {
      const deleteAuthorizationAndFlow = await streamManagerContract
        .connect(signer)
        .deleteAuthorizationAndFlow(superTokenAddress, receiverAddress);

      return await this.parseTx(
        streamManagerContract,
        deleteAuthorizationAndFlow,
      );
    } catch (error) {
      return this.getTxErrorResponse(error);
    }
  }
}
