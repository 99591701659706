import { Web3AuthService } from "./web3AuthService.js";
import { Web3Provider } from "@ethersproject/providers";
import { StreamService } from "./streamService.js";
import { initStreamSDK, streamSDK } from "./useStreamSDK.js";
import { BigNumber } from "ethers";
import { WalletService } from "./walletService.js";
import { ADAPTER_STATUS } from "@web3auth/base";
import { isHexString, parseUnits } from "ethers/lib/utils.js";
import {
  optionsStore,
  originWalletStore,
  transactionProcessingStepStore,
} from "../../store.js";
import {
  PaymentProcessingSteps,
  AuthorizationActions,
} from "../../shared/utils/utils.svelte";
import { get } from "svelte/store";

const speedFactor = process.env.WEB3_SPEED_FACTOR;

const loadChainConfig = (network) => {
  const WEB3AUTH_CLIENT_ID = process.env.WEB3AUTH_CLIENT_ID;
  const WEB3AUTH_NETWORK = process.env.WEB3AUTH_NETWORK;

  switch (network) {
    case "BINANCE_TEST_NET":
      return {
        clientId: WEB3AUTH_CLIENT_ID,
        displayName: network,
        chainId: process.env.BSC_TESTNET_CHAIN_ID_HEX,
        rpcTarget: process.env.BSC_TESTNET_RPC,
        web3AuthNetwork: WEB3AUTH_NETWORK,
        blockExplorer: process.env.BSC_TESTNET_BLOCK_EXPLORER,
        ticker: "BNB",
      };
    case "BINANCE_MAIN_NET":
      return {
        clientId: WEB3AUTH_CLIENT_ID,
        displayName: network,
        chainId: process.env.BSC_MAINNET_CHAIN_ID_HEX,
        rpcTarget: process.env.BSC_MAINNET_RPC,
        web3AuthNetwork: WEB3AUTH_NETWORK,
        blockExplorer: process.env.BSC_MAINNET_BLOCK_EXPLORER,
        ticker: "BNB",
      };
    case "GNOSIS_MAIN_NET":
      return {
        clientId: WEB3AUTH_CLIENT_ID,
        displayName: network,
        chainId: process.env.GNOSIS_CHAIN_ID_HEX,
        rpcTarget: process.env.GNOSIS_RPC,
        web3AuthNetwork: WEB3AUTH_NETWORK,
        blockExplorer: process.env.GNOSIS_BLOCK_EXPLORER,
        ticker: "XDAI",
      };
  }
};

// login action
const handleLogin = (chainConfig) => {
  const web3AuthService = new Web3AuthService(chainConfig);

  return new Promise(async (resolve, reject) => {
    await web3AuthService.initializeWeb3Auth();

    // when the status of the web3auth instance is connected, the first click result to the user as doing nothing
    // so we trigger logout to change the status of the instance to "ready" for a new transaction
    if (web3AuthService.web3auth.status === ADAPTER_STATUS.CONNECTED) {
      await web3AuthService.logout();
    }

    try {
      // Subscribers for web3auth sdk events
      web3AuthService.subscribeAuthEvents({
        connectedFn: async () => {
          console.log("Listener: Connected to wallet");

          const web3auth = web3AuthService.getWeb3AuthSdk();

          if (!web3auth.provider) {
            console.log("Listener: Problem with Provider");
            reject("Problem with Provider");
            return;
          }

          const provider = new Web3Provider(web3auth.provider);
          const chainId = (await provider.getNetwork()).chainId;
          const signerAddress = await provider.getSigner().getAddress();
          originWalletStore.set(signerAddress);
          const rpc = web3auth.options.chainConfig.rpcTarget;

          if (!provider || !signerAddress || !chainId) {
            reject("Missing web3 provider values");
            return;
          }

          console.log("Listener: Connected successfully");

          await initStreamSDK(provider);

          resolve({
            provider,
            signerAddress,
            chainId,
            rpc,
          });
        },

        connectingFn: () => {
          transactionProcessingStepStore.set(
            PaymentProcessingSteps.WALLET_CONNECT,
          );
          console.log("Listener: Connecting to wallet");
        },

        disconnectedFn: () => {
          console.log("Listener: Disconnected from wallet");
          web3AuthService.reload();
        },

        erroredFn: (error) => {
          console.log("Listener: Disconnected from wallet");

          if (error.message === "User rejected the request.") {
            reject(error.message);
            return;
          }

          web3AuthService.reload(); // TODO test this case?

          reject(error);

          console.log("Listener: Error connecting to wallet", error);
        },
      });

      // reload the service to ensure new data is read
      web3AuthService.reload();

      await web3AuthService.connectWallet();
    } catch (exception) {
      console.error("Problem with SDK", exception);
      reject(exception);
    }
  });
};

// create or update authorization (client -> merchant)
export const handleAuthorization = async ({
  tokenAddress,
  receiverAddress,
  maxFlowRate,
  maxDuration,
  maxAmount,
  decimals,
  sessionId,
  paymentProvider,
  network,
  paymentType,
  onTransactionCreated,
  streamAmount,
  isCryptoNative,
  streamManagerAddress,
  gelatoFee,
  minDepositInSeconds,
  transactionHash,
}) => {
  let transactionResponse;
  let streamPaymentData;
  let allowanceTxResponse;
  const options = get(optionsStore);
  const chainConfig = loadChainConfig(network);
  const { provider, signerAddress } = await handleLogin(chainConfig);
  const walletService = new WalletService(provider, speedFactor);
  const streamService = new StreamService(
    provider,
    streamSDK.instance,
    speedFactor,
  );

  if (!streamManagerAddress) {
    return {
      transactionResponse: {
        error: true,
        codeName: "MISSING_STREAM_MANAGER_ADDRESS",
      },
    };
  }

  const { streamAuthorizationStatus } = await options.getAuthorizationStatus({
    tokenAddress,
    network,
    fromWalletAddress: signerAddress,
    toWalletAddress: receiverAddress,
    givenFlowRate: streamAmount,
  });

  if (
    streamAuthorizationStatus ===
    AuthorizationActions.NEW_AUTHORIZATION_REQUIRED
  ) {
    allowanceTxResponse = await streamService.updateFlowPermissions({
      superTokenAddress: tokenAddress,
      streamManagerAddress,
    });

    if (allowanceTxResponse.error) {
      return {
        transactionResponse: {
          error: true,
          codeName: allowanceTxResponse.codeName ?? allowanceTxResponse,
        },
      };
    }
  }

  const minDepositAmount = parseUnits(streamAmount, decimals).mul(
    minDepositInSeconds,
  );

  const balance = isCryptoNative
    ? await walletService.balanceOfNative()
    : await walletService.balanceOfErc20(signerAddress, tokenAddress);

  if (balance.lt(minDepositAmount)) {
    return {
      transactionResponse: {
        error: true,
        codeName: "INSUFFICIENT_BALANCE_FOR_STREAM",
        walletAddress: signerAddress,
      },
    };
  }

  // This step is almost invisible and the customer want it to be shown to the user
  setTimeout(
    () =>
      transactionProcessingStepStore.set(PaymentProcessingSteps.CONFIRMATION),
    5000,
  );

  const authParams = {
    superTokenAddress: tokenAddress,
    streamManagerAddress,
    receiverAddress: receiverAddress,
    maxFlowRate: maxFlowRate,
    maxDuration: maxDuration,
    maxAmount: maxAmount,
    gelatoFee: BigNumber.from(gelatoFee),
  };

  if (
    streamAuthorizationStatus ===
    AuthorizationActions.NEW_AUTHORIZATION_REQUIRED
  ) {
    transactionResponse = await streamService.createAuthorization(authParams);
  } else if (
    streamAuthorizationStatus ===
    AuthorizationActions.UPDATE_AUTHORIZATION_REQUIRED
  ) {
    transactionResponse = await streamService.updateAuthorization(authParams);
  } else if (
    streamAuthorizationStatus ===
    AuthorizationActions.NO_UPDATE_AUTHORIZATION_REQUIRED
  ) {
    streamPaymentData = await onTransactionCreated(
      sessionId,
      paymentProvider,
      transactionHash,
      network,
      signerAddress,
      receiverAddress,
      maxFlowRate,
      0,
      tokenAddress,
      maxDuration,
      paymentType,
      AuthorizationActions.NO_UPDATE_AUTHORIZATION_REQUIRED,
    );

    return {
      transactionResponse: {
        error: false,
      },
      streamPaymentData,
    };
  } else {
    return {
      transactionResponse: {
        error: true,
        codeName: "UNSUPPORTED_AUTH_ACTION",
      },
    };
  }

  if (transactionResponse.error) {
    // authorization already exists
    if (
      transactionResponse.codeName ===
      "Authorization__AuthorizedFlowAlreadyExists"
    ) {
      // update auth
      transactionResponse = await streamService.updateAuthorization(authParams);

      if (transactionResponse.error) {
        return {
          transactionResponse: {
            error: true,
            codeName: transactionResponse.codeName,
          },
        };
      }

      if (transactionResponse.hash && !isHexString(transactionResponse.hash)) {
        return {
          transactionResponse: {
            error: true,
            codeName: "Invalid transaction hash",
          },
        };
      }

      streamPaymentData = await onTransactionCreated(
        sessionId,
        paymentProvider,
        transactionResponse.hash,
        network,
        signerAddress,
        receiverAddress,
        maxFlowRate,
        0,
        tokenAddress,
        maxDuration,
        paymentType,
        AuthorizationActions.UPDATE_AUTHORIZATION_REQUIRED,
      );

      return { transactionResponse, streamPaymentData };
    } else {
      return {
        transactionResponse: {
          error: true,
          codeName: transactionResponse.codeName,
          message: transactionResponse.message,
        },
      };
    }
  }

  if (transactionResponse.hash && !isHexString(transactionResponse.hash)) {
    return {
      transactionResponse: {
        error: true,
        codeName: "Invalid transaction hash",
      },
    };
  }

  streamPaymentData = await onTransactionCreated(
    sessionId,
    paymentProvider,
    transactionResponse.hash,
    network,
    signerAddress,
    receiverAddress,
    maxFlowRate,
    0,
    tokenAddress,
    maxDuration,
    paymentType,
    streamAuthorizationStatus,
  );

  return { transactionResponse, streamPaymentData };
};

// transfer amount on address + checking balance
export const handleFixedCryptoTransfer = async ({
  isCryptoNative,
  tokenAddress,
  receiverAddress,
  amountToTransfer,
  network,
  sessionId,
  maxFlowRate,
  maxDuration,
  paymentType,
  onTransactionCreated,
}) => {
  const chainConfig = loadChainConfig(network);
  const { provider, signerAddress } = await handleLogin(chainConfig);

  const walletService = new WalletService(provider, speedFactor);

  const balance = isCryptoNative
    ? await walletService.balanceOfNative()
    : await walletService.balanceOfErc20(signerAddress, tokenAddress);

  // check if the balance is enough to make the transfer
  const canProcessTransfer = balance.gte(BigNumber.from(amountToTransfer));

  if (!canProcessTransfer) {
    return {
      transferResponse: { error: true, codeName: "INSUFFICIENT_FUNDS" },
    };
  }

  // if you verified balance above - transfer money
  const transferResponse = isCryptoNative
    ? await walletService.transferNative(receiverAddress, amountToTransfer)
    : await walletService.transferErc20(
        receiverAddress,
        amountToTransfer,
        tokenAddress,
      );

  if (transferResponse.error) {
    return {
      transferResponse: { error: true, codeName: transferResponse.codeName },
    };
  }

  if (transferResponse.hash && !isHexString(transferResponse.hash)) {
    return {
      transferResponse: {
        error: true,
        codeName: "Invalid transaction hash",
      },
    };
  }

  const streamPaymentData = await onTransactionCreated(
    sessionId,
    "CRYPTO",
    transferResponse.hash,
    network,
    signerAddress,
    receiverAddress,
    maxFlowRate,
    0,
    tokenAddress,
    maxDuration,
    paymentType,
  );

  return { transferResponse, streamPaymentData };
};

// USED ONLY FOR TESTING PURPOSE
// delete authorization (client -> merchant)
export const deleteAuthorization = async ({
  tokenAddress,
  receiverAddress,
  network,
  streamManagerAddress,
}) => {
  const chainConfig = loadChainConfig(network);
  const { provider, signerAddress } = await handleLogin(chainConfig);

  const streamService = new StreamService(
    provider,
    streamSDK.instance,
    speedFactor,
  );

  const deleteAuthResponse = await streamService.deleteAuthorization({
    superTokenAddress: tokenAddress,
    receiverAddress: receiverAddress,
    streamManagerAddress: streamManagerAddress,
  });

  console.log("Auth deleted, response: ", { deleteAuthResponse });
};
