import { Framework } from "@streamable-finance/sdk-core";

let _streamSDKInstance;

export const streamSDK = {
  get instance() {
    if (!_streamSDKInstance) return undefined;

    return _streamSDKInstance;
  },
  set instance(_) {
    throw "Cannot set SDK directly. Call initStreamSDK function.";
  },
};

export const initStreamSDK = async (provider) => {
  const currentNetwork = await provider?.getNetwork();
  const newChainId = currentNetwork?.chainId;

  const newSDK = await Framework.create({
    chainId: newChainId,
    provider: provider,
  });

  _streamSDKInstance = newSDK;

  return newSDK;
};
