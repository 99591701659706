<svg
  width="66"
  height="19"
  viewBox="0 0 66 19"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.8366 8.72766C9.94972 8.09857 8.36832 8.0774 7.62379 8.33712C7.6007 8.34482 7.58916 8.3679 7.59301 8.39099C7.59686 8.41408 7.61609 8.43139 7.6411 8.43139C7.6411 8.43139 7.65842 8.43139 7.66611 8.43139C8.57994 8.43139 9.33986 8.70842 9.75348 9.19516C9.96895 9.4491 10.0632 9.73768 10.0171 10.007C9.86891 10.8766 8.57609 11.7058 6.2771 12.4022C4.48985 12.9428 2.7026 13.2275 2.36208 13.2795C2.31591 13.2872 2.2832 13.3256 2.2832 13.3718V14.2587C2.2832 14.2856 2.29475 14.3126 2.31591 14.3299C2.33322 14.3453 2.35439 14.353 2.37747 14.353C2.38132 14.353 2.38709 14.353 2.39286 14.353C2.99887 14.2587 5.14011 13.8951 7.20054 13.2218C10.044 12.2906 11.4869 11.1921 11.4888 9.95315C11.4888 9.4491 11.2714 9.0374 10.8405 8.73151L10.8366 8.72766Z"
    fill="#686BFC"
  />
  <path
    d="M10.8368 12.0056C9.94217 12.7251 7.23148 14.4681 1.4157 15.1319C1.36761 15.1376 1.33105 15.178 1.33105 15.2242V16.1246C1.33105 16.1515 1.3426 16.1765 1.36184 16.1938C1.37915 16.2092 1.40031 16.2188 1.4234 16.2188C1.42532 16.2188 1.42917 16.2188 1.43109 16.2188C2.15254 16.1592 8.54931 15.5301 10.9695 12.1345C10.9964 12.096 10.9926 12.0441 10.958 12.0095C10.9233 11.9768 10.8733 11.9748 10.8348 12.0037L10.8368 12.0056Z"
    fill="#686BFC"
  />
  <path
    d="M7.9397 15.0725C7.3183 15.4285 4.91542 16.6212 0.0865729 16.9752C0.0384769 16.9791 0 17.0195 0 17.0695V18.016C0 18.041 0.00961921 18.066 0.0288576 18.0834C0.0461722 18.1007 0.0692583 18.1103 0.0923444 18.1103C0.59062 18.1007 5.04432 17.9448 8.02435 15.1899C8.05128 15.1649 8.05513 15.1245 8.03204 15.0937C8.01088 15.0629 7.97048 15.0552 7.93777 15.0725H7.9397Z"
    fill="#686BFC"
  />
  <path
    d="M0.648335 10.2557C1.19855 10.6463 2.01811 10.804 2.73763 10.804C3.17626 10.804 3.58027 10.7463 3.86115 10.6463C3.88424 10.6386 3.89578 10.6155 3.89193 10.5924C3.88809 10.5693 3.86885 10.552 3.84384 10.552C3.84384 10.552 3.82652 10.552 3.81883 10.552C2.905 10.552 2.14508 10.2749 1.73146 9.78822C1.51599 9.53427 1.42172 9.24569 1.46789 8.97636C1.61603 8.10678 2.90885 7.2776 5.20784 6.58117C6.99317 6.04057 8.78234 5.75584 9.12286 5.7039C9.16903 5.6962 9.20174 5.65773 9.20174 5.61155V4.72466C9.20174 4.69773 9.1902 4.6708 9.16903 4.65348C9.14787 4.63617 9.12094 4.62847 9.094 4.63232C8.48799 4.72659 6.34676 5.09019 4.28632 5.76354C1.44481 6.69468 0 7.79512 0 9.03407C0 9.53812 0.217394 9.94982 0.648335 10.2557Z"
    fill="#686BFC"
  />
  <path
    d="M0.592518 6.99111C0.61368 6.99111 0.632919 6.98341 0.650233 6.96995C1.54482 6.25043 4.25551 4.50743 10.0713 3.8437C10.1194 3.83793 10.154 3.79753 10.154 3.75136V2.851C10.154 2.82407 10.1425 2.79906 10.1232 2.78174C10.104 2.76443 10.079 2.75481 10.054 2.75865C9.33253 2.81829 2.93576 3.44739 0.515564 6.8449C0.488631 6.88337 0.492478 6.93532 0.529031 6.96995C0.54827 6.98726 0.569432 6.99688 0.592518 6.99688V6.99111Z"
    fill="#686BFC"
  />
  <path
    d="M11.3933 0.868861C10.8951 0.878481 6.44136 1.03431 3.46133 3.78925C3.4344 3.81426 3.43055 3.85467 3.45364 3.88545C3.4671 3.90469 3.49019 3.9143 3.51135 3.9143C3.52289 3.9143 3.53636 3.91046 3.5479 3.90469C4.1693 3.54877 6.57218 2.35599 11.401 2.002C11.4491 1.99816 11.4876 1.95776 11.4876 1.90774V0.96313C11.4876 0.93812 11.478 0.91311 11.4587 0.895795C11.4414 0.878481 11.4203 0.866938 11.3933 0.868861Z"
    fill="#686BFC"
  />
  <path
    d="M18.082 5.43659L17.5053 5.25329C17.1526 5.13745 16.5623 4.94349 16.5623 4.43619C16.5623 4.04207 16.9203 3.7669 17.4323 3.7669C17.9443 3.7669 18.3174 4.04473 18.4896 4.54937C18.4953 4.56668 18.5081 4.58088 18.525 4.58843C18.5418 4.59597 18.5608 4.59642 18.5776 4.58932L19.2193 4.32657C19.2507 4.3137 19.2671 4.27952 19.2583 4.24712C19.0467 3.48329 18.3431 2.98975 17.4651 2.98975C16.587 2.98975 15.6891 3.50459 15.6891 4.48812C15.6891 5.55465 16.7318 5.88042 17.1247 6.00337L17.6447 6.1627C18.0975 6.30251 18.5254 6.43477 18.5254 6.92476C18.5254 7.44582 18.0324 7.67928 17.5438 7.67928C16.9309 7.67928 16.4768 7.2998 16.2981 6.63805C16.2932 6.62029 16.2812 6.6052 16.2649 6.59677C16.2485 6.58834 16.2295 6.58656 16.2122 6.59277L15.5572 6.82268C15.5245 6.83422 15.5063 6.86884 15.5147 6.90213C15.7688 7.87944 16.53 8.46264 17.5505 8.46264C18.6325 8.46264 19.418 7.77736 19.418 6.83289C19.418 5.88841 18.6144 5.60747 18.0824 5.4357L18.082 5.43659Z"
    fill="black"
  />
  <path
    d="M24.1457 3.81689V3.16667C24.1457 3.12983 24.116 3.1001 24.0793 3.1001H20.084C20.0472 3.1001 20.0176 3.12983 20.0176 3.16667V3.81689C20.0176 3.85372 20.0472 3.88346 20.084 3.88346H21.6617V8.28982C21.6617 8.32666 21.6913 8.3564 21.728 8.3564H22.4352C22.472 8.3564 22.5016 8.32666 22.5016 8.28982V3.88346H24.0793C24.116 3.88346 24.1457 3.85372 24.1457 3.81689Z"
    fill="black"
  />
  <path
    d="M29.0252 8.32178C29.0367 8.30003 29.0354 8.27385 29.0221 8.25343C28.6008 7.61653 28.1662 6.96898 27.7449 6.34096C28.3437 6.2118 28.9482 5.73468 28.9482 4.74982C28.9482 3.44496 27.7781 3.1001 27.1594 3.1001H25.2861C25.2494 3.1001 25.2197 3.12983 25.2197 3.16667V8.29027C25.2197 8.32711 25.2494 8.35684 25.2861 8.35684H25.9867C26.0234 8.35684 26.053 8.32711 26.053 8.29027V6.41242H26.8483L28.1189 8.32711C28.1313 8.34575 28.1521 8.35684 28.1742 8.35684H28.9668C28.9911 8.35684 29.0137 8.34353 29.0252 8.32178ZM26.053 3.87725H27.0346C27.5148 3.87725 28.0751 4.11115 28.0751 4.76979C28.0751 4.97751 28.0016 5.65568 27.0541 5.65568H26.053V3.87725Z"
    fill="black"
  />
  <path
    d="M33.6823 7.56017H31.116V6.07732H33.4858C33.5226 6.07732 33.5522 6.04759 33.5522 6.01075V5.33435C33.5522 5.29751 33.5226 5.26777 33.4858 5.26777H31.116V3.88346H33.6168C33.6536 3.88346 33.6832 3.85372 33.6832 3.81689V3.16667C33.6832 3.12983 33.6536 3.1001 33.6168 3.1001H30.3486C30.3119 3.1001 30.2822 3.12983 30.2822 3.16667V8.29027C30.2822 8.32711 30.3119 8.35685 30.3486 8.35685H33.6823C33.7191 8.35685 33.7487 8.32711 33.7487 8.29027V7.62674C33.7487 7.5899 33.7191 7.56017 33.6823 7.56017Z"
    fill="black"
  />
  <path
    d="M37.4307 3.14226C37.4205 3.11696 37.3962 3.1001 37.3688 3.1001H36.5633C36.5359 3.1001 36.5115 3.11696 36.5014 3.14226L34.4909 8.26586C34.4829 8.28628 34.4855 8.30936 34.4979 8.32755C34.5103 8.34575 34.5307 8.35685 34.5528 8.35685H35.2928C35.3198 8.35685 35.3445 8.34042 35.3543 8.31512C35.4592 8.05193 35.5623 7.78475 35.6623 7.52644C35.7565 7.28188 35.8544 7.02934 35.9535 6.78035H37.9529L38.565 8.31512C38.5751 8.34042 38.5995 8.35685 38.6265 8.35685H39.3797C39.4018 8.35685 39.4222 8.34575 39.4346 8.32755C39.447 8.30936 39.4496 8.28628 39.4417 8.26586L37.4312 3.14226H37.4307ZM37.6511 5.97036H36.2611L36.9612 4.17683C37.1599 4.71298 37.3913 5.31482 37.6507 5.97036H37.6511Z"
    fill="black"
  />
  <path
    d="M45.9361 3.10054H45.0652C45.0391 3.10054 45.0156 3.11608 45.0046 3.1396C44.7001 3.81644 44.3921 4.50571 44.0942 5.17279C43.8141 5.79993 43.5251 6.44748 43.2383 7.08482C42.6741 5.81768 42.0798 4.49107 41.4712 3.1396C41.4606 3.11563 41.4367 3.10054 41.4106 3.10054H40.5459C40.5091 3.10054 40.4795 3.13028 40.4795 3.16712V8.29072C40.4795 8.32755 40.5091 8.35729 40.5459 8.35729H41.2597C41.2964 8.35729 41.3261 8.32755 41.3261 8.29072V4.77734C41.5682 5.32458 41.8111 5.87227 42.0541 6.41952C42.3351 7.05198 42.6157 7.68488 42.8949 8.31734C42.9056 8.34131 42.9294 8.35685 42.9556 8.35685H43.5189C43.545 8.35685 43.5689 8.34131 43.5796 8.31734C43.857 7.68888 44.1358 7.06041 44.4151 6.43195C44.662 5.87538 44.9094 5.31881 45.1555 4.76225V8.29027C45.1555 8.32711 45.1851 8.35685 45.2219 8.35685H45.9357C45.9724 8.35685 46.0021 8.32711 46.0021 8.29027V3.16667C46.0021 3.12983 45.9724 3.1001 45.9357 3.1001L45.9361 3.10054Z"
    fill="black"
  />
  <path
    d="M49.9747 3.14226C49.9645 3.11696 49.9401 3.1001 49.9127 3.1001H49.1073C49.0798 3.1001 49.0555 3.11696 49.0453 3.14226L47.0348 8.26586C47.0268 8.28628 47.0295 8.30936 47.0419 8.32755C47.0543 8.34575 47.0746 8.35685 47.0968 8.35685H47.8367C47.8637 8.35685 47.8885 8.34042 47.8982 8.31512C48.0031 8.05193 48.1062 7.78475 48.2062 7.52644C48.3005 7.28188 48.3983 7.02934 48.4974 6.78035H50.4969L51.1089 8.31512C51.1191 8.34042 51.1434 8.35685 51.1704 8.35685H51.9236C51.9458 8.35685 51.9661 8.34575 51.9785 8.32755C51.9909 8.30936 51.9936 8.28628 51.9856 8.26586L49.9751 3.14226H49.9747ZM50.195 5.97036H48.805L49.5051 4.17683C49.7038 4.71298 49.9353 5.31482 50.1946 5.97036H50.195Z"
    fill="black"
  />
  <path
    d="M55.9923 5.665C56.5282 5.424 56.7239 4.90339 56.7239 4.49994C56.7239 3.80313 56.2092 3.1001 55.0594 3.1001H53.0879C53.0511 3.1001 53.0215 3.12983 53.0215 3.16667V8.29027C53.0215 8.32711 53.0511 8.35685 53.0879 8.35685H55.1705C55.6166 8.35685 56.0339 8.23035 56.3455 8.00089C56.7044 7.73637 56.8942 7.35734 56.8942 6.90463C56.8942 6.29436 56.561 5.84298 55.9923 5.66545V5.665ZM53.8552 6.08398H54.9873C55.4989 6.08398 56.0144 6.31921 56.0144 6.84515C56.0144 7.18113 55.7489 7.57348 55.0001 7.57348H53.8548V6.08442L53.8552 6.08398ZM54.8762 5.33346H53.8552V3.88346H54.8563C55.3515 3.88346 55.8507 4.1107 55.8507 4.61845C55.8507 4.96419 55.5945 5.33346 54.8758 5.33346H54.8762Z"
    fill="black"
  />
  <path
    d="M61.3934 7.56017H59.0169V3.16667C59.0169 3.12983 58.9873 3.1001 58.9505 3.1001H58.25C58.2132 3.1001 58.1836 3.12983 58.1836 3.16667V8.29027C58.1836 8.32711 58.2132 8.35685 58.25 8.35685H61.3939C61.4306 8.35685 61.4602 8.32711 61.4602 8.29027V7.62674C61.4602 7.5899 61.4306 7.56017 61.3939 7.56017H61.3934Z"
    fill="black"
  />
  <path
    d="M65.9343 7.56017H63.3679V6.07732H65.7378C65.7745 6.07732 65.8042 6.04759 65.8042 6.01075V5.33435C65.8042 5.29751 65.7745 5.26777 65.7378 5.26777H63.3679V3.88346H65.8688C65.9055 3.88346 65.9352 3.85372 65.9352 3.81689V3.16667C65.9352 3.12983 65.9055 3.1001 65.8688 3.1001H62.6006C62.5638 3.1001 62.5342 3.12983 62.5342 3.16667V8.29027C62.5342 8.32711 62.5638 8.35685 62.6006 8.35685H65.9343C65.971 8.35685 66.0007 8.32711 66.0007 8.29027V7.62674C66.0007 7.5899 65.971 7.56017 65.9343 7.56017Z"
    fill="black"
  />
  <path
    d="M19.1869 10.6436H15.9775C15.9408 10.6436 15.9111 10.6733 15.9111 10.7101V15.8337C15.9111 15.8706 15.9408 15.9003 15.9775 15.9003H16.6781C16.7148 15.9003 16.7445 15.8706 16.7445 15.8337V13.6998H19.0554C19.0922 13.6998 19.1218 13.67 19.1218 13.6332V12.9568C19.1218 12.92 19.0922 12.8902 19.0554 12.8902H16.7445V11.4402H19.1864C19.2232 11.4402 19.2528 11.4105 19.2528 11.3737V10.7101C19.2528 10.6733 19.2232 10.6436 19.1864 10.6436H19.1869Z"
    fill="black"
  />
  <path
    d="M21.2318 10.6436H20.5312C20.4946 10.6436 20.4648 10.6734 20.4648 10.7101V15.8337C20.4648 15.8705 20.4946 15.9003 20.5312 15.9003H21.2318C21.2684 15.9003 21.2982 15.8705 21.2982 15.8337V10.7101C21.2982 10.6734 21.2684 10.6436 21.2318 10.6436Z"
    fill="black"
  />
  <path
    d="M27.0998 10.644H26.3993C26.3626 10.644 26.3329 10.6737 26.3329 10.7106V14.4317C25.6695 13.4353 25.0013 12.4677 24.3538 11.5303C24.1564 11.2445 23.9595 10.9591 23.7621 10.6728C23.7497 10.6547 23.7294 10.644 23.7077 10.644H22.9677C22.931 10.644 22.9014 10.6737 22.9014 10.7106V15.8342C22.9014 15.871 22.931 15.9007 22.9677 15.9007H23.6683C23.705 15.9007 23.7347 15.871 23.7347 15.8342V12.1695C24.3034 13.0105 24.8774 13.8298 25.4336 14.6238C25.72 15.0326 26.016 15.4551 26.3055 15.8719C26.3179 15.8896 26.3382 15.9003 26.3599 15.9003H27.0998C27.1366 15.9003 27.1662 15.8706 27.1662 15.8337V10.7101C27.1662 10.6733 27.1366 10.6436 27.0998 10.6436V10.644Z"
    fill="black"
  />
  <path
    d="M31.1465 10.6857C31.1364 10.6604 31.112 10.6436 31.0846 10.6436H30.2791C30.2517 10.6436 30.2274 10.6604 30.2172 10.6857L28.2067 15.8093C28.1987 15.8297 28.2014 15.8528 28.2138 15.871C28.2262 15.8892 28.2465 15.9003 28.2686 15.9003H29.0086C29.0356 15.9003 29.0604 15.8839 29.0701 15.8586C29.175 15.5954 29.2781 15.3282 29.3781 15.0699C29.4724 14.8253 29.5702 14.5728 29.6693 14.3238H31.6687L32.2808 15.8586C32.291 15.8839 32.3153 15.9003 32.3423 15.9003H33.0955C33.1177 15.9003 33.138 15.8892 33.1504 15.871C33.1628 15.8528 33.1654 15.8297 33.1575 15.8093L31.147 10.6857H31.1465ZM31.3669 13.5138H29.9769L30.677 11.7203C30.8757 12.2564 31.1071 12.8583 31.3665 13.5138H31.3669Z"
    fill="black"
  />
  <path
    d="M38.3918 10.644H37.6913C37.6545 10.644 37.6249 10.6737 37.6249 10.7106V14.4317C36.9615 13.4353 36.2933 12.4677 35.6458 11.5303C35.4484 11.2445 35.2515 10.9591 35.0541 10.6728C35.0417 10.6547 35.0214 10.644 34.9997 10.644H34.2597C34.223 10.644 34.1934 10.6737 34.1934 10.7106V15.8342C34.1934 15.871 34.223 15.9007 34.2597 15.9007H34.9603C34.997 15.9007 35.0267 15.871 35.0267 15.8342V12.1695C35.5954 13.0105 36.1693 13.8298 36.7256 14.6238C37.012 15.0326 37.308 15.4551 37.5975 15.8719C37.6098 15.8896 37.6302 15.9003 37.6519 15.9003H38.3918C38.4286 15.9003 38.4582 15.8706 38.4582 15.8337V10.7101C38.4582 10.6733 38.4286 10.6436 38.3918 10.6436V10.644Z"
    fill="black"
  />
  <path
    d="M44.8902 14.0411L44.2419 13.8112C44.2246 13.805 44.2056 13.8063 44.1896 13.8148C44.1733 13.8232 44.1613 13.8378 44.156 13.8552C43.9051 14.7055 43.2678 15.2133 42.4513 15.2133C41.5388 15.2133 40.6183 14.6203 40.6183 13.2964C40.6183 12.1406 41.3423 11.3333 42.3792 11.3333C43.0961 11.3333 43.69 11.7474 43.9679 12.4411C43.9746 12.4575 43.9874 12.4704 44.0033 12.4775C44.0197 12.4846 44.0378 12.4846 44.0542 12.4779L44.7026 12.2152C44.7353 12.2019 44.7521 12.1655 44.7406 12.1318C44.41 11.1629 43.4855 10.5366 42.3854 10.5366C40.8555 10.5366 39.7451 11.6861 39.7451 13.2702C39.7451 14.8542 40.8471 16.01 42.4248 16.01C43.6351 16.01 44.5486 15.3229 44.9309 14.1246C44.942 14.0904 44.9238 14.0535 44.8898 14.0416L44.8902 14.0411Z"
    fill="black"
  />
  <path
    d="M49.4275 15.1036H46.8611V13.6208H49.231C49.2677 13.6208 49.2973 13.591 49.2973 13.5542V12.8778C49.2973 12.841 49.2677 12.8112 49.231 12.8112H46.8611V11.4269H49.362C49.3987 11.4269 49.4283 11.3972 49.4283 11.3603V10.7101C49.4283 10.6733 49.3987 10.6436 49.362 10.6436H46.0937C46.057 10.6436 46.0273 10.6733 46.0273 10.7101V15.8337C46.0273 15.8706 46.057 15.9003 46.0937 15.9003H49.4275C49.4642 15.9003 49.4938 15.8706 49.4938 15.8337V15.1702C49.4938 15.1334 49.4642 15.1036 49.4275 15.1036Z"
    fill="black"
  />
</svg>
