<script>
  export let width,
    height,
    viewBox,
    isSmall = false;
</script>

<svg fill="none" {height} {viewBox} {width} xmlns="http://www.w3.org/2000/svg">
  {#if isSmall}
    <!-- the icon follow the colors of the text and is smaller -->
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 16.089844 11.785156 C 14.773438 10.839844 12.425781 10.808594 11.320312 11.199219 C 11.285156 11.210938 11.269531 11.246094 11.273438 11.28125 C 11.28125 11.3125 11.308594 11.339844 11.347656 11.339844 C 11.347656 11.339844 11.371094 11.339844 11.382812 11.339844 C 12.742188 11.339844 13.867188 11.753906 14.484375 12.484375 C 14.804688 12.867188 14.941406 13.300781 14.875 13.703125 C 14.65625 15.007812 12.734375 16.25 9.320312 17.292969 C 6.667969 18.105469 4.015625 18.53125 3.507812 18.609375 C 3.441406 18.621094 3.390625 18.679688 3.390625 18.75 L 3.390625 20.078125 C 3.390625 20.117188 3.40625 20.160156 3.441406 20.183594 C 3.464844 20.207031 3.496094 20.21875 3.53125 20.21875 C 3.535156 20.21875 3.546875 20.21875 3.554688 20.21875 C 4.453125 20.078125 7.632812 19.53125 10.691406 18.523438 C 14.914062 17.128906 17.058594 15.480469 17.058594 13.621094 C 17.058594 12.867188 16.738281 12.25 16.097656 11.789062 Z M 16.089844 11.785156 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 16.089844 16.699219 C 14.761719 17.78125 10.738281 20.394531 2.101562 21.386719 C 2.03125 21.398438 1.976562 21.457031 1.976562 21.527344 L 1.976562 22.875 C 1.976562 22.917969 1.992188 22.953125 2.023438 22.980469 C 2.046875 23.003906 2.078125 23.019531 2.113281 23.019531 C 2.117188 23.019531 2.121094 23.019531 2.125 23.019531 C 3.195312 22.929688 12.695312 21.984375 16.289062 16.894531 C 16.328125 16.835938 16.324219 16.757812 16.269531 16.707031 C 16.21875 16.65625 16.144531 16.652344 16.089844 16.699219 Z M 16.089844 16.699219 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 11.789062 21.296875 C 10.867188 21.832031 7.296875 23.621094 0.128906 24.152344 C 0.0585938 24.15625 0 24.21875 0 24.292969 L 0 25.710938 C 0 25.75 0.015625 25.789062 0.0429688 25.8125 C 0.0703125 25.839844 0.101562 25.855469 0.136719 25.855469 C 0.878906 25.839844 7.488281 25.605469 11.914062 21.472656 C 11.953125 21.4375 11.960938 21.375 11.925781 21.332031 C 11.894531 21.285156 11.835938 21.273438 11.785156 21.296875 Z M 11.789062 21.296875 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 0.960938 14.074219 C 1.78125 14.660156 2.996094 14.898438 4.066406 14.898438 C 4.714844 14.898438 5.316406 14.8125 5.734375 14.660156 C 5.765625 14.648438 5.785156 14.617188 5.777344 14.582031 C 5.773438 14.546875 5.746094 14.519531 5.707031 14.519531 C 5.707031 14.519531 5.683594 14.519531 5.671875 14.519531 C 4.3125 14.519531 3.183594 14.105469 2.570312 13.375 C 2.25 12.992188 2.109375 12.5625 2.179688 12.15625 C 2.398438 10.851562 4.320312 9.609375 7.734375 8.566406 C 10.382812 7.753906 13.039062 7.328125 13.546875 7.25 C 13.613281 7.238281 13.664062 7.179688 13.664062 7.113281 L 13.664062 5.78125 C 13.664062 5.742188 13.644531 5.703125 13.613281 5.675781 C 13.582031 5.648438 13.542969 5.636719 13.503906 5.644531 C 12.601562 5.785156 9.425781 6.328125 6.363281 7.339844 C 2.144531 8.734375 0 10.386719 0 12.242188 C 0 13 0.324219 13.617188 0.960938 14.074219 Z M 0.960938 14.074219 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 0.878906 9.179688 C 0.910156 9.179688 0.941406 9.167969 0.964844 9.148438 C 2.292969 8.070312 6.320312 5.457031 14.953125 4.460938 C 15.027344 4.453125 15.078125 4.390625 15.078125 4.324219 L 15.078125 2.972656 C 15.078125 2.933594 15.058594 2.894531 15.03125 2.867188 C 15.003906 2.84375 14.964844 2.828125 14.929688 2.832031 C 13.859375 2.921875 4.359375 3.867188 0.765625 8.960938 C 0.726562 9.019531 0.730469 9.097656 0.785156 9.148438 C 0.8125 9.175781 0.84375 9.1875 0.878906 9.1875 Z M 0.878906 9.179688 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 16.917969 0 C 16.175781 0.015625 9.5625 0.25 5.140625 4.378906 C 5.097656 4.417969 5.09375 4.476562 5.128906 4.523438 C 5.148438 4.550781 5.183594 4.566406 5.214844 4.566406 C 5.230469 4.566406 5.25 4.5625 5.269531 4.550781 C 6.191406 4.019531 9.757812 2.230469 16.929688 1.699219 C 17 1.695312 17.058594 1.632812 17.058594 1.558594 L 17.058594 0.140625 C 17.058594 0.105469 17.042969 0.0664062 17.015625 0.0390625 C 16.988281 0.015625 16.957031 -0.00390625 16.917969 0 Z M 16.917969 0 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 26.847656 6.847656 L 25.992188 6.574219 C 25.46875 6.402344 24.59375 6.109375 24.59375 5.347656 C 24.59375 4.757812 25.125 4.34375 25.882812 4.34375 C 26.644531 4.34375 27.199219 4.761719 27.453125 5.519531 C 27.460938 5.542969 27.480469 5.566406 27.507812 5.578125 C 27.53125 5.589844 27.558594 5.589844 27.585938 5.578125 L 28.539062 5.183594 C 28.585938 5.164062 28.609375 5.113281 28.59375 5.066406 C 28.28125 3.921875 27.234375 3.179688 25.933594 3.179688 C 24.628906 3.179688 23.296875 3.953125 23.296875 5.425781 C 23.296875 7.027344 24.84375 7.515625 25.425781 7.699219 L 26.199219 7.9375 C 26.871094 8.148438 27.507812 8.347656 27.507812 9.082031 C 27.507812 9.863281 26.773438 10.210938 26.050781 10.210938 C 25.140625 10.210938 24.464844 9.644531 24.199219 8.652344 C 24.191406 8.625 24.175781 8.601562 24.152344 8.589844 C 24.125 8.578125 24.097656 8.574219 24.074219 8.582031 L 23.101562 8.925781 C 23.050781 8.945312 23.023438 8.996094 23.035156 9.046875 C 23.414062 10.511719 24.542969 11.386719 26.058594 11.386719 C 27.667969 11.386719 28.832031 10.359375 28.832031 8.941406 C 28.832031 7.527344 27.640625 7.105469 26.847656 6.847656 Z M 26.847656 6.847656 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 35.851562 4.417969 L 35.851562 3.445312 C 35.851562 3.390625 35.808594 3.34375 35.753906 3.34375 L 29.820312 3.34375 C 29.765625 3.34375 29.722656 3.390625 29.722656 3.445312 L 29.722656 4.417969 C 29.722656 4.476562 29.765625 4.519531 29.820312 4.519531 L 32.164062 4.519531 L 32.164062 11.125 C 32.164062 11.183594 32.207031 11.226562 32.261719 11.226562 L 33.3125 11.226562 C 33.367188 11.226562 33.410156 11.183594 33.410156 11.125 L 33.410156 4.519531 L 35.753906 4.519531 C 35.808594 4.519531 35.851562 4.476562 35.851562 4.417969 Z M 35.851562 4.417969 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 43.097656 11.175781 C 43.113281 11.140625 43.113281 11.101562 43.09375 11.070312 C 42.46875 10.117188 41.820312 9.144531 41.195312 8.203125 C 42.085938 8.011719 42.984375 7.296875 42.984375 5.820312 C 42.984375 3.863281 41.246094 3.34375 40.328125 3.34375 L 37.546875 3.34375 C 37.492188 3.34375 37.445312 3.390625 37.445312 3.445312 L 37.445312 11.128906 C 37.445312 11.183594 37.492188 11.226562 37.546875 11.226562 L 38.585938 11.226562 C 38.640625 11.226562 38.683594 11.183594 38.683594 11.128906 L 38.683594 8.3125 L 39.863281 8.3125 L 41.75 11.183594 C 41.769531 11.210938 41.800781 11.226562 41.832031 11.226562 L 43.011719 11.226562 C 43.046875 11.226562 43.082031 11.207031 43.097656 11.175781 Z M 38.683594 4.511719 L 40.140625 4.511719 C 40.855469 4.511719 41.6875 4.859375 41.6875 5.847656 C 41.6875 6.160156 41.578125 7.175781 40.171875 7.175781 L 38.683594 7.175781 Z M 38.683594 4.511719 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 50.011719 10.03125 L 46.203125 10.03125 L 46.203125 7.808594 L 49.722656 7.808594 C 49.773438 7.808594 49.820312 7.765625 49.820312 7.710938 L 49.820312 6.695312 C 49.820312 6.640625 49.773438 6.59375 49.722656 6.59375 L 46.203125 6.59375 L 46.203125 4.519531 L 49.914062 4.519531 C 49.96875 4.519531 50.011719 4.476562 50.011719 4.417969 L 50.011719 3.445312 C 50.011719 3.390625 49.96875 3.34375 49.914062 3.34375 L 45.0625 3.34375 C 45.007812 3.34375 44.964844 3.390625 44.964844 3.445312 L 44.964844 11.128906 C 44.964844 11.183594 45.007812 11.226562 45.0625 11.226562 L 50.011719 11.226562 C 50.066406 11.226562 50.109375 11.183594 50.109375 11.128906 L 50.109375 10.132812 C 50.109375 10.078125 50.066406 10.03125 50.011719 10.03125 Z M 50.011719 10.03125 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 55.578125 3.40625 C 55.5625 3.371094 55.527344 3.34375 55.484375 3.34375 L 54.289062 3.34375 C 54.25 3.34375 54.214844 3.371094 54.199219 3.40625 L 51.214844 11.089844 C 51.203125 11.121094 51.207031 11.15625 51.222656 11.183594 C 51.242188 11.210938 51.273438 11.226562 51.304688 11.226562 L 52.402344 11.226562 C 52.445312 11.226562 52.480469 11.203125 52.496094 11.164062 C 52.652344 10.769531 52.804688 10.371094 52.953125 9.980469 C 53.09375 9.613281 53.238281 9.238281 53.386719 8.863281 L 56.355469 8.863281 L 57.261719 11.164062 C 57.277344 11.203125 57.3125 11.226562 57.355469 11.226562 L 58.472656 11.226562 C 58.503906 11.226562 58.535156 11.210938 58.554688 11.183594 C 58.570312 11.15625 58.578125 11.121094 58.5625 11.089844 Z M 55.90625 7.648438 L 53.839844 7.648438 L 54.882812 4.960938 C 55.175781 5.761719 55.519531 6.664062 55.90625 7.648438 Z M 55.90625 7.648438 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 68.207031 3.34375 L 66.914062 3.34375 C 66.875 3.34375 66.839844 3.367188 66.824219 3.402344 C 66.371094 4.417969 65.914062 5.453125 65.472656 6.453125 C 65.054688 7.394531 64.628906 8.363281 64.203125 9.320312 C 63.363281 7.417969 62.480469 5.429688 61.578125 3.402344 C 61.5625 3.367188 61.527344 3.34375 61.488281 3.34375 L 60.203125 3.34375 C 60.148438 3.34375 60.105469 3.390625 60.105469 3.445312 L 60.105469 11.128906 C 60.105469 11.183594 60.148438 11.226562 60.203125 11.226562 L 61.261719 11.226562 C 61.316406 11.226562 61.363281 11.183594 61.363281 11.128906 L 61.363281 5.859375 C 61.722656 6.679688 62.082031 7.5 62.441406 8.320312 C 62.859375 9.269531 63.277344 10.21875 63.691406 11.167969 C 63.707031 11.203125 63.742188 11.226562 63.78125 11.226562 L 64.617188 11.226562 C 64.65625 11.226562 64.691406 11.203125 64.707031 11.167969 C 65.121094 10.226562 65.535156 9.28125 65.949219 8.339844 C 66.316406 7.507812 66.683594 6.671875 67.046875 5.835938 L 67.046875 11.128906 C 67.046875 11.183594 67.089844 11.226562 67.148438 11.226562 L 68.207031 11.226562 C 68.261719 11.226562 68.304688 11.183594 68.304688 11.128906 L 68.304688 3.445312 C 68.304688 3.390625 68.261719 3.34375 68.207031 3.34375 Z M 68.207031 3.34375 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 74.203125 3.40625 C 74.1875 3.371094 74.152344 3.34375 74.109375 3.34375 L 72.914062 3.34375 C 72.875 3.34375 72.839844 3.371094 72.824219 3.40625 L 69.835938 11.089844 C 69.828125 11.121094 69.828125 11.15625 69.847656 11.183594 C 69.867188 11.210938 69.898438 11.226562 69.929688 11.226562 L 71.027344 11.226562 C 71.070312 11.226562 71.105469 11.203125 71.121094 11.164062 C 71.277344 10.769531 71.429688 10.371094 71.578125 9.980469 C 71.71875 9.613281 71.863281 9.238281 72.007812 8.863281 L 74.980469 8.863281 L 75.886719 11.164062 C 75.902344 11.203125 75.9375 11.226562 75.980469 11.226562 L 77.097656 11.226562 C 77.128906 11.226562 77.160156 11.210938 77.179688 11.183594 C 77.195312 11.15625 77.199219 11.121094 77.1875 11.089844 Z M 74.53125 7.648438 L 72.464844 7.648438 L 73.503906 4.960938 C 73.800781 5.761719 74.144531 6.664062 74.53125 7.648438 Z M 74.53125 7.648438 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 83.140625 7.191406 C 83.933594 6.828125 84.226562 6.046875 84.226562 5.445312 C 84.226562 4.398438 83.460938 3.34375 81.753906 3.34375 L 78.824219 3.34375 C 78.773438 3.34375 78.726562 3.390625 78.726562 3.445312 L 78.726562 11.128906 C 78.726562 11.183594 78.773438 11.226562 78.824219 11.226562 L 81.917969 11.226562 C 82.582031 11.226562 83.199219 11.039062 83.664062 10.695312 C 84.195312 10.296875 84.476562 9.726562 84.476562 9.050781 C 84.476562 8.132812 83.984375 7.457031 83.140625 7.191406 Z M 79.964844 7.820312 L 81.644531 7.820312 C 82.40625 7.820312 83.171875 8.171875 83.171875 8.960938 C 83.171875 9.464844 82.777344 10.050781 81.664062 10.050781 L 79.964844 10.050781 Z M 81.480469 6.695312 L 79.964844 6.695312 L 79.964844 4.519531 L 81.453125 4.519531 C 82.1875 4.519531 82.929688 4.859375 82.929688 5.621094 C 82.929688 6.140625 82.546875 6.695312 81.480469 6.695312 Z M 81.480469 6.695312 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 91.160156 10.03125 L 87.632812 10.03125 L 87.632812 3.445312 C 87.632812 3.390625 87.585938 3.34375 87.53125 3.34375 L 86.492188 3.34375 C 86.4375 3.34375 86.394531 3.390625 86.394531 3.445312 L 86.394531 11.128906 C 86.394531 11.183594 86.4375 11.226562 86.492188 11.226562 L 91.160156 11.226562 C 91.214844 11.226562 91.257812 11.183594 91.257812 11.128906 L 91.257812 10.132812 C 91.257812 10.078125 91.214844 10.03125 91.160156 10.03125 Z M 91.160156 10.03125 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 97.902344 10.03125 L 94.089844 10.03125 L 94.089844 7.808594 L 97.609375 7.808594 C 97.664062 7.808594 97.707031 7.765625 97.707031 7.710938 L 97.707031 6.695312 C 97.707031 6.640625 97.664062 6.59375 97.609375 6.59375 L 94.089844 6.59375 L 94.089844 4.519531 L 97.804688 4.519531 C 97.859375 4.519531 97.902344 4.476562 97.902344 4.417969 L 97.902344 3.445312 C 97.902344 3.390625 97.859375 3.34375 97.804688 3.34375 L 92.949219 3.34375 C 92.898438 3.34375 92.851562 3.390625 92.851562 3.445312 L 92.851562 11.128906 C 92.851562 11.183594 92.898438 11.226562 92.949219 11.226562 L 97.902344 11.226562 C 97.957031 11.226562 98 11.183594 98 11.128906 L 98 10.132812 C 98 10.078125 97.957031 10.03125 97.902344 10.03125 Z M 97.902344 10.03125 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 28.488281 14.65625 L 23.722656 14.65625 C 23.667969 14.65625 23.625 14.699219 23.625 14.757812 L 23.625 22.4375 C 23.625 22.496094 23.667969 22.539062 23.722656 22.539062 L 24.761719 22.539062 C 24.816406 22.539062 24.863281 22.496094 24.863281 22.4375 L 24.863281 19.238281 L 28.292969 19.238281 C 28.347656 19.238281 28.390625 19.195312 28.390625 19.140625 L 28.390625 18.125 C 28.390625 18.070312 28.347656 18.027344 28.292969 18.027344 L 24.863281 18.027344 L 24.863281 15.851562 L 28.488281 15.851562 C 28.542969 15.851562 28.585938 15.804688 28.585938 15.75 L 28.585938 14.757812 C 28.585938 14.699219 28.542969 14.65625 28.488281 14.65625 Z M 28.488281 14.65625 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 31.523438 14.65625 L 30.484375 14.65625 C 30.429688 14.65625 30.386719 14.703125 30.386719 14.757812 L 30.386719 22.4375 C 30.386719 22.496094 30.429688 22.539062 30.484375 22.539062 L 31.523438 22.539062 C 31.578125 22.539062 31.621094 22.496094 31.621094 22.4375 L 31.621094 14.757812 C 31.621094 14.703125 31.578125 14.65625 31.523438 14.65625 Z M 31.523438 14.65625 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 40.238281 14.65625 L 39.199219 14.65625 C 39.144531 14.65625 39.101562 14.703125 39.101562 14.757812 L 39.101562 20.335938 C 38.113281 18.84375 37.121094 17.390625 36.160156 15.984375 C 35.867188 15.558594 35.574219 15.128906 35.28125 14.699219 C 35.265625 14.671875 35.234375 14.65625 35.203125 14.65625 L 34.101562 14.65625 C 34.046875 14.65625 34.003906 14.703125 34.003906 14.757812 L 34.003906 22.441406 C 34.003906 22.496094 34.046875 22.539062 34.101562 22.539062 L 35.144531 22.539062 C 35.199219 22.539062 35.242188 22.496094 35.242188 22.441406 L 35.242188 16.945312 C 36.085938 18.207031 36.9375 19.433594 37.765625 20.625 C 38.191406 21.238281 38.628906 21.871094 39.058594 22.496094 C 39.078125 22.523438 39.109375 22.539062 39.140625 22.539062 L 40.238281 22.539062 C 40.292969 22.539062 40.335938 22.496094 40.335938 22.4375 L 40.335938 14.757812 C 40.335938 14.699219 40.292969 14.65625 40.238281 14.65625 Z M 40.238281 14.65625 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 46.246094 14.71875 C 46.230469 14.683594 46.195312 14.65625 46.15625 14.65625 L 44.960938 14.65625 C 44.917969 14.65625 44.882812 14.683594 44.867188 14.71875 L 41.882812 22.402344 C 41.871094 22.433594 41.875 22.46875 41.890625 22.496094 C 41.910156 22.523438 41.941406 22.539062 41.972656 22.539062 L 43.070312 22.539062 C 43.113281 22.539062 43.148438 22.515625 43.164062 22.476562 C 43.320312 22.082031 43.472656 21.679688 43.621094 21.292969 C 43.761719 20.925781 43.90625 20.546875 44.054688 20.175781 L 47.023438 20.175781 L 47.929688 22.476562 C 47.945312 22.515625 47.980469 22.539062 48.023438 22.539062 L 49.140625 22.539062 C 49.171875 22.539062 49.203125 22.523438 49.222656 22.496094 C 49.242188 22.46875 49.246094 22.433594 49.234375 22.402344 Z M 46.574219 18.960938 L 44.511719 18.960938 L 45.550781 16.269531 C 45.84375 17.074219 46.1875 17.976562 46.574219 18.960938 Z M 46.574219 18.960938 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 57.003906 14.65625 L 55.964844 14.65625 C 55.910156 14.65625 55.867188 14.703125 55.867188 14.757812 L 55.867188 20.335938 C 54.882812 18.84375 53.890625 17.390625 52.929688 15.984375 C 52.632812 15.558594 52.34375 15.128906 52.050781 14.699219 C 52.03125 14.671875 52 14.65625 51.96875 14.65625 L 50.871094 14.65625 C 50.816406 14.65625 50.769531 14.703125 50.769531 14.757812 L 50.769531 22.441406 C 50.769531 22.496094 50.816406 22.539062 50.871094 22.539062 L 51.910156 22.539062 C 51.964844 22.539062 52.007812 22.496094 52.007812 22.441406 L 52.007812 16.945312 C 52.851562 18.207031 53.707031 19.433594 54.53125 20.625 C 54.957031 21.238281 55.394531 21.871094 55.824219 22.496094 C 55.84375 22.523438 55.875 22.539062 55.90625 22.539062 L 57.003906 22.539062 C 57.058594 22.539062 57.105469 22.496094 57.105469 22.4375 L 57.105469 14.757812 C 57.105469 14.699219 57.058594 14.65625 57.003906 14.65625 Z M 57.003906 14.65625 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 66.652344 19.75 L 65.691406 19.40625 C 65.664062 19.398438 65.636719 19.398438 65.613281 19.414062 C 65.589844 19.425781 65.570312 19.445312 65.5625 19.472656 C 65.191406 20.75 64.246094 21.507812 63.03125 21.507812 C 61.675781 21.507812 60.308594 20.621094 60.308594 18.636719 C 60.308594 16.902344 61.386719 15.691406 62.925781 15.691406 C 63.988281 15.691406 64.871094 16.3125 65.285156 17.351562 C 65.292969 17.378906 65.3125 17.398438 65.335938 17.40625 C 65.359375 17.417969 65.386719 17.417969 65.410156 17.40625 L 66.375 17.015625 C 66.421875 16.992188 66.449219 16.9375 66.429688 16.890625 C 65.941406 15.4375 64.566406 14.496094 62.933594 14.496094 C 60.664062 14.496094 59.015625 16.21875 59.015625 18.597656 C 59.015625 20.972656 60.648438 22.703125 62.992188 22.703125 C 64.789062 22.703125 66.144531 21.675781 66.714844 19.878906 C 66.730469 19.824219 66.703125 19.769531 66.652344 19.753906 Z M 66.652344 19.75 "
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M 73.390625 21.34375 L 69.582031 21.34375 L 69.582031 19.121094 L 73.101562 19.121094 C 73.15625 19.121094 73.199219 19.078125 73.199219 19.019531 L 73.199219 18.007812 C 73.199219 17.953125 73.15625 17.90625 73.101562 17.90625 L 69.582031 17.90625 L 69.582031 15.832031 L 73.292969 15.832031 C 73.347656 15.832031 73.394531 15.785156 73.394531 15.730469 L 73.394531 14.757812 C 73.394531 14.699219 73.347656 14.65625 73.292969 14.65625 L 68.441406 14.65625 C 68.386719 14.65625 68.34375 14.699219 68.34375 14.757812 L 68.34375 22.4375 C 68.34375 22.496094 68.386719 22.539062 68.441406 22.539062 L 73.390625 22.539062 C 73.445312 22.539062 73.488281 22.496094 73.488281 22.4375 L 73.488281 21.445312 C 73.488281 21.390625 73.445312 21.34375 73.390625 21.34375 Z M 73.390625 21.34375    "
    />
  {:else}
    <!-- the icon have the original color and is bigger -->
    <path
      fill="#686BFC"
      d="M38.7508 28.1014C35.5795 25.8519 29.9248 25.7763 27.2626 26.705C27.18 26.7325 27.1388 26.815 27.1525 26.8976C27.1663 26.9801 27.2351 27.042 27.3245 27.042C27.3245 27.042 27.3864 27.042 27.4139 27.042C30.6815 27.042 33.3988 28.0326 34.8778 29.7731C35.6483 30.6811 35.9854 31.713 35.8203 32.6761C35.2906 35.7855 30.6678 38.7504 22.4472 41.2406C16.0564 43.1737 9.66567 44.1918 8.44806 44.3775C8.28296 44.4051 8.16602 44.5426 8.16602 44.7077V47.879C8.16602 47.9754 8.20729 48.0717 8.28296 48.1336C8.34487 48.1886 8.42054 48.2161 8.50309 48.2161C8.51685 48.2161 8.53749 48.2161 8.55813 48.2161C10.7251 47.879 18.3816 46.5789 25.7492 44.1712C35.9166 40.8417 41.076 36.9136 41.0828 32.4835C41.0828 30.6811 40.3055 29.209 38.7646 28.1152L38.7508 28.1014Z"
    />
    <path
      fill="#686BFC"
      d="M38.7507 39.8241C35.5519 42.3969 25.8592 48.6294 5.06343 51.0027C4.89145 51.0234 4.76074 51.1678 4.76074 51.3329V54.5524C4.76074 54.6487 4.80202 54.7381 4.87081 54.8C4.93272 54.8551 5.00839 54.8894 5.09094 54.8894C5.09782 54.8894 5.11158 54.8894 5.11846 54.8894C7.69815 54.6762 30.5714 52.4267 39.2254 40.285C39.3217 40.1474 39.3079 39.9616 39.1841 39.8378C39.0603 39.7209 38.8814 39.714 38.7438 39.8172L38.7507 39.8241Z"
    />
    <path
      fill="#686BFC"
      d="M28.3903 50.7888C26.1684 52.0614 17.5763 56.3265 0.309563 57.5923C0.137583 57.606 0 57.7505 0 57.9294V61.3139C0 61.4033 0.0343959 61.4928 0.103188 61.5547C0.1651 61.6166 0.24765 61.651 0.3302 61.651C2.11191 61.6166 18.0372 61.0594 28.693 51.2084C28.7893 51.119 28.8031 50.9745 28.7205 50.8645C28.6449 50.7544 28.5004 50.7269 28.3835 50.7888H28.3903Z"
    />
    <path
      fill="#686BFC"
      d="M2.31828 33.5654C4.28572 34.9619 7.21625 35.526 9.78906 35.526C11.3575 35.526 12.8021 35.3196 13.8065 34.9619C13.889 34.9343 13.9303 34.8518 13.9166 34.7692C13.9028 34.6867 13.834 34.6248 13.7446 34.6248C13.7446 34.6248 13.6827 34.6248 13.6552 34.6248C10.3875 34.6248 7.67027 33.6342 6.19125 31.8937C5.42079 30.9857 5.08371 29.9538 5.24881 28.9907C5.7785 25.8814 10.4013 22.9164 18.6219 20.4262C25.0058 18.4931 31.4034 17.475 32.621 17.2893C32.7861 17.2618 32.9031 17.1242 32.9031 16.9591V13.7878C32.9031 13.6915 32.8618 13.5952 32.7861 13.5332C32.7105 13.4713 32.6141 13.4438 32.5178 13.4576C30.3509 13.7947 22.6944 15.0948 15.3268 17.5025C5.16626 20.832 0 24.7669 0 29.1971C0 30.9995 0.777346 32.4716 2.31828 33.5654Z"
    />
    <path
      fill="#686BFC"
      d="M2.11905 21.8921C2.19473 21.8921 2.26352 21.8646 2.32543 21.8164C5.52424 19.2436 15.217 13.0111 36.0127 10.6377C36.1847 10.6171 36.3085 10.4726 36.3085 10.3075V7.08809C36.3085 6.99179 36.2673 6.90236 36.1985 6.84044C36.1297 6.77853 36.0402 6.74414 35.9508 6.75789C33.3711 6.97115 10.4979 9.22064 1.84389 21.3693C1.74758 21.5068 1.76134 21.6926 1.89204 21.8164C1.96083 21.8783 2.0365 21.9127 2.11905 21.9127V21.8921Z"
    />
    <path
      fill="#686BFC"
      d="M40.7385 0.000747402C38.9568 0.0351433 23.0315 0.592356 12.3757 10.4433C12.2794 10.5328 12.2656 10.6772 12.3481 10.7873C12.3963 10.8561 12.4789 10.8905 12.5545 10.8905C12.5958 10.8905 12.644 10.8767 12.6852 10.8561C14.9072 9.58343 23.4993 5.31835 40.766 4.05258C40.938 4.03882 41.0756 3.89436 41.0756 3.7155V0.337827C41.0756 0.248398 41.0412 0.158968 40.9724 0.0970558C40.9105 0.0351433 40.8348 -0.00613177 40.7385 0.000747402Z"
    />
    <path
      fill="black"
      d="M64.6558 16.3328L62.5939 15.6773C61.3327 15.2631 59.2217 14.5696 59.2217 12.7556C59.2217 11.3463 60.5019 10.3624 62.3328 10.3624C64.1637 10.3624 65.4977 11.3559 66.1133 13.1603C66.1338 13.2222 66.1797 13.273 66.2399 13.3C66.3 13.327 66.368 13.3285 66.4282 13.3032L68.7227 12.3636C68.8351 12.3176 68.8936 12.1954 68.862 12.0796C68.1056 9.34827 65.5895 7.5835 62.4499 7.5835C59.3103 7.5835 56.0996 9.42445 56.0996 12.9413C56.0996 16.7549 59.8278 17.9198 61.233 18.3594L63.0924 18.9292C64.7112 19.4291 66.2414 19.902 66.2414 21.6541C66.2414 23.5173 64.4786 24.3521 62.7316 24.3521C60.5399 24.3521 58.9163 22.9951 58.277 20.6289C58.2596 20.5654 58.2169 20.5114 58.1583 20.4813C58.0998 20.4511 58.0317 20.4448 57.97 20.467L55.628 21.2891C55.5109 21.3303 55.446 21.4541 55.4761 21.5732C56.3844 25.0678 59.1062 27.1532 62.7553 27.1532C66.6244 27.1532 69.4332 24.7028 69.4332 21.3256C69.4332 17.9484 66.5595 16.9438 64.6574 16.3296L64.6558 16.3328Z"
    />
    <path
      fill="black"
      d="M86.3382 10.5386V8.21364C86.3382 8.08192 86.2322 7.97559 86.1008 7.97559H71.8145C71.6832 7.97559 71.5771 8.08192 71.5771 8.21364V10.5386C71.5771 10.6704 71.6832 10.7767 71.8145 10.7767H77.4559V26.5327C77.4559 26.6644 77.562 26.7708 77.6933 26.7708H80.222C80.3534 26.7708 80.4594 26.6644 80.4594 26.5327V10.7767H86.1008C86.2322 10.7767 86.3382 10.6704 86.3382 10.5386Z"
    />
    <path
      fill="black"
      d="M103.785 26.647C103.826 26.5692 103.822 26.4756 103.774 26.4026C102.268 24.1252 100.714 21.8097 99.2072 19.5641C101.348 19.1023 103.51 17.3962 103.51 13.8746C103.51 9.20871 99.3259 7.97559 97.1136 7.97559H90.4151C90.2838 7.97559 90.1777 8.08192 90.1777 8.21364V26.5343C90.1777 26.666 90.2838 26.7724 90.4151 26.7724H92.9201C93.0515 26.7724 93.1575 26.666 93.1575 26.5343V19.8196H96.0011L100.544 26.666C100.589 26.7327 100.663 26.7724 100.742 26.7724H103.576C103.663 26.7724 103.744 26.7248 103.785 26.647ZM93.1575 10.7545H96.6673C98.3843 10.7545 100.388 11.5908 100.388 13.946C100.388 14.6887 100.125 17.1137 96.737 17.1137H93.1575V10.7545Z"
    />
    <path
      fill="black"
      d="M120.438 23.9237H111.262V18.6214H119.736C119.867 18.6214 119.973 18.5151 119.973 18.3833V15.9647C119.973 15.833 119.867 15.7266 119.736 15.7266H111.262V10.7767H120.204C120.335 10.7767 120.441 10.6704 120.441 10.5386V8.21364C120.441 8.08192 120.335 7.97559 120.204 7.97559H108.518C108.386 7.97559 108.28 8.08192 108.28 8.21364V26.5343C108.28 26.666 108.386 26.7724 108.518 26.7724H120.438C120.57 26.7724 120.676 26.666 120.676 26.5343V24.1617C120.676 24.03 120.57 23.9237 120.438 23.9237Z"
    />
    <path
      fill="black"
      d="M133.842 8.12635C133.805 8.03589 133.718 7.97559 133.62 7.97559H130.74C130.642 7.97559 130.555 8.03589 130.519 8.12635L123.33 26.447C123.301 26.52 123.311 26.6026 123.355 26.6676C123.399 26.7327 123.472 26.7724 123.551 26.7724H126.197C126.294 26.7724 126.382 26.7137 126.417 26.6232C126.792 25.6821 127.161 24.7267 127.518 23.803C127.855 22.9286 128.205 22.0256 128.56 21.1352H135.709L137.898 26.6232C137.934 26.7137 138.021 26.7724 138.118 26.7724H140.811C140.89 26.7724 140.963 26.7327 141.007 26.6676C141.051 26.6026 141.061 26.52 141.032 26.447L133.843 8.12635H133.842ZM134.63 18.2389H129.659L132.163 11.8257C132.873 13.7429 133.701 15.8949 134.628 18.2389H134.63Z"
    />
    <path
      fill="black"
      d="M164.254 7.97717H161.139C161.046 7.97717 160.962 8.03272 160.923 8.11683C159.834 10.5371 158.733 13.0017 157.668 15.387C156.666 17.6295 155.633 19.945 154.607 22.2239C152.59 17.693 150.464 12.9493 148.288 8.11683C148.25 8.03113 148.165 7.97717 148.072 7.97717H144.98C144.848 7.97717 144.742 8.0835 144.742 8.21523V26.5359C144.742 26.6676 144.848 26.774 144.98 26.774H147.532C147.663 26.774 147.769 26.6676 147.769 26.5359V13.973C148.635 15.9298 149.504 17.8882 150.373 19.845C151.377 22.1065 152.381 24.3696 153.379 26.6311C153.417 26.7168 153.503 26.7724 153.596 26.7724H155.61C155.704 26.7724 155.789 26.7168 155.827 26.6311C156.819 24.3839 157.816 22.1367 158.815 19.8894C159.698 17.8993 160.582 15.9092 161.462 13.919V26.5343C161.462 26.666 161.568 26.7724 161.7 26.7724H164.252C164.383 26.7724 164.49 26.666 164.49 26.5343V8.21364C164.49 8.08192 164.383 7.97559 164.252 7.97559L164.254 7.97717Z"
    />
    <path
      d="M178.693 8.12635C178.657 8.03589 178.57 7.97559 178.472 7.97559H175.592C175.494 7.97559 175.407 8.03589 175.37 8.12635L168.181 26.447C168.153 26.52 168.162 26.6026 168.207 26.6676C168.251 26.7327 168.324 26.7724 168.403 26.7724H171.049C171.145 26.7724 171.234 26.7137 171.269 26.6232C171.644 25.6821 172.012 24.7267 172.37 23.803C172.707 22.9286 173.057 22.0256 173.411 21.1352H180.561L182.749 26.6232C182.786 26.7137 182.873 26.7724 182.969 26.7724H185.662C185.742 26.7724 185.814 26.7327 185.859 26.6676C185.903 26.6026 185.913 26.52 185.884 26.447L178.695 8.12635H178.693ZM179.481 18.2389H174.511L177.014 11.8257C177.725 13.7429 178.553 15.8949 179.48 18.2389H179.481Z"
      fill="black"
    />
    <path
      d="M200.212 17.147C202.128 16.2853 202.828 14.4237 202.828 12.9811C202.828 10.4894 200.987 7.97559 196.876 7.97559H189.826C189.695 7.97559 189.589 8.08192 189.589 8.21364V26.5343C189.589 26.666 189.695 26.7724 189.826 26.7724H197.273C198.868 26.7724 200.361 26.3201 201.475 25.4996C202.758 24.5537 203.437 23.1984 203.437 21.5796C203.437 19.3974 202.245 17.7834 200.212 17.1486V17.147ZM192.57 18.6452H196.618C198.447 18.6452 200.291 19.4863 200.291 21.3669C200.291 22.5683 199.341 23.9713 196.664 23.9713H192.569V18.6468L192.57 18.6452ZM196.221 15.9615H192.57V10.7767H196.15C197.92 10.7767 199.705 11.5893 199.705 13.4048C199.705 14.6411 198.789 15.9615 196.219 15.9615H196.221Z"
      fill="black"
    />
    <path
      d="M219.527 23.9237H211.03V8.21364C211.03 8.08192 210.924 7.97559 210.792 7.97559H208.287C208.156 7.97559 208.05 8.08192 208.05 8.21364V26.5343C208.05 26.666 208.156 26.7724 208.287 26.7724H219.529C219.66 26.7724 219.766 26.666 219.766 26.5343V24.1617C219.766 24.03 219.66 23.9237 219.529 23.9237H219.527Z"
      fill="black"
    />
    <path
      d="M235.761 23.9237H226.585V18.6214H235.059C235.19 18.6214 235.296 18.5151 235.296 18.3833V15.9647C235.296 15.833 235.19 15.7266 235.059 15.7266H226.585V10.7767H235.527C235.659 10.7767 235.765 10.6704 235.765 10.5386V8.21364C235.765 8.08192 235.659 7.97559 235.527 7.97559H223.841C223.71 7.97559 223.604 8.08192 223.604 8.21364V26.5343C223.604 26.666 223.71 26.7724 223.841 26.7724H235.761C235.893 26.7724 235.999 26.666 235.999 26.5343V24.1617C235.999 24.03 235.893 23.9237 235.761 23.9237Z"
      fill="black"
    />
    <path
      d="M68.6049 34.9502H57.129C56.9976 34.9502 56.8916 35.0565 56.8916 35.1882V53.5089C56.8916 53.6406 56.9976 53.747 57.129 53.747H59.634C59.7653 53.747 59.8713 53.6406 59.8713 53.5089V45.8785H68.1349C68.2662 45.8785 68.3722 45.7722 68.3722 45.6404V43.2218C68.3722 43.0901 68.2662 42.9837 68.1349 42.9837H59.8713V37.7989H68.6033C68.7346 37.7989 68.8406 37.6926 68.8406 37.5609V35.1882C68.8406 35.0565 68.7346 34.9502 68.6033 34.9502H68.6049Z"
      fill="black"
    />
    <path
      d="M75.9152 34.9502H73.4102C73.2791 34.9502 73.1729 35.0568 73.1729 35.1882V53.5089C73.1729 53.6404 73.2791 53.747 73.4102 53.747H75.9152C76.0463 53.747 76.1526 53.6404 76.1526 53.5089V35.1882C76.1526 35.0568 76.0463 34.9502 75.9152 34.9502Z"
      fill="black"
    />
    <path
      d="M96.9013 34.9518H94.3963C94.265 34.9518 94.159 35.0581 94.159 35.1898V48.4955C91.7869 44.9326 89.3974 41.4729 87.0823 38.1211C86.3765 37.099 85.6723 36.0786 84.9665 35.0549C84.9222 34.9899 84.8494 34.9518 84.7719 34.9518H82.126C81.9947 34.9518 81.8887 35.0581 81.8887 35.1898V53.5105C81.8887 53.6422 81.9947 53.7486 82.126 53.7486H84.6311C84.7624 53.7486 84.8684 53.6422 84.8684 53.5105V40.4064C86.9019 43.4138 88.9543 46.3435 90.9434 49.1827C91.9673 50.6443 93.0259 52.1552 94.0608 53.6454C94.1052 53.7089 94.1779 53.747 94.2555 53.747H96.9013C97.0327 53.747 97.1387 53.6406 97.1387 53.5089V35.1882C97.1387 35.0565 97.0327 34.9502 96.9013 34.9502V34.9518Z"
      fill="black"
    />
    <path
      d="M111.37 35.101C111.334 35.0105 111.247 34.9502 111.149 34.9502H108.269C108.17 34.9502 108.083 35.0105 108.047 35.101L100.858 53.4216C100.83 53.4946 100.839 53.5772 100.883 53.6422C100.928 53.7073 101 53.747 101.08 53.747H103.725C103.822 53.747 103.911 53.6883 103.945 53.5978C104.32 52.6567 104.689 51.7013 105.047 50.7776C105.384 49.9032 105.733 49.0002 106.088 48.1098H113.237L115.426 53.5978C115.462 53.6883 115.549 53.747 115.646 53.747H118.339C118.418 53.747 118.491 53.7073 118.535 53.6422C118.58 53.5772 118.589 53.4946 118.561 53.4216L111.372 35.101H111.37ZM112.158 45.2135H107.188L109.691 38.8003C110.402 40.7175 111.229 42.8695 112.157 45.2135H112.158Z"
      fill="black"
    />
    <path
      d="M137.278 34.9518H134.773C134.642 34.9518 134.536 35.0581 134.536 35.1898V48.4955C132.164 44.9326 129.774 41.4729 127.459 38.1211C126.753 37.099 126.049 36.0786 125.343 35.0549C125.299 34.9899 125.226 34.9518 125.149 34.9518H122.503C122.372 34.9518 122.266 35.0581 122.266 35.1898V53.5105C122.266 53.6422 122.372 53.7486 122.503 53.7486H125.008C125.139 53.7486 125.245 53.6422 125.245 53.5105V40.4064C127.279 43.4138 129.331 46.3435 131.32 49.1827C132.344 50.6443 133.403 52.1552 134.438 53.6454C134.482 53.7089 134.555 53.747 134.632 53.747H137.278C137.41 53.747 137.516 53.6406 137.516 53.5089V35.1882C137.516 35.0565 137.41 34.9502 137.278 34.9502V34.9518Z"
      fill="black"
    />
    <path
      d="M160.513 47.1005L158.194 46.2784C158.133 46.2562 158.065 46.261 158.008 46.2911C157.949 46.3213 157.906 46.3737 157.887 46.4356C156.99 49.4763 154.711 51.2919 151.792 51.2919C148.529 51.2919 145.237 49.1716 145.237 44.4375C145.237 40.3049 147.826 37.4181 151.534 37.4181C154.098 37.4181 156.221 38.8988 157.215 41.3793C157.239 41.438 157.285 41.484 157.342 41.5094C157.4 41.5348 157.465 41.5348 157.523 41.511L159.842 40.5715C159.959 40.5239 160.019 40.3937 159.978 40.2731C158.796 36.8086 155.49 34.5693 151.556 34.5693C146.086 34.5693 142.115 38.6797 142.115 44.3439C142.115 50.008 146.056 54.1406 151.697 54.1406C156.025 54.1406 159.291 51.6839 160.658 47.3989C160.698 47.2767 160.633 47.145 160.511 47.1021L160.513 47.1005Z"
      fill="black"
    />
    <path
      d="M176.739 50.8983H167.562V45.596H176.036C176.168 45.596 176.274 45.4897 176.274 45.3579V42.9393C176.274 42.8076 176.168 42.7012 176.036 42.7012H167.562V37.7513H176.505C176.636 37.7513 176.742 37.645 176.742 37.5132V35.1882C176.742 35.0565 176.636 34.9502 176.505 34.9502H164.818C164.687 34.9502 164.581 35.0565 164.581 35.1882V53.5089C164.581 53.6406 164.687 53.747 164.818 53.747H176.739C176.87 53.747 176.976 53.6406 176.976 53.5089V51.1363C176.976 51.0046 176.87 50.8983 176.739 50.8983Z"
      fill="black"
    />
  {/if}
</svg>
