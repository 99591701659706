<script>
  import { CardCvc, CardExpiry, CardNumber, Elements } from "svelte-stripe";
  import { get } from "svelte/store";
  import { optionsStore } from "../../store.js";
  import FooterIcon from "../icons/FooterIcon.svelte";
  import ErrorIcon from "../icons/ErrorIcon.svelte";
  import SuccessIcon from "../icons/SuccessIcon.svelte";

  export let isCardModalVisible;
  export let loadDataError;
  export let stripe;
  export let paymentData;
  export let onCloseModal;
  export let successUrl;
  export let errorUrl;
  export let sessionId;

  const redirectDelayTimeMs = 2500;

  let isPaymentProcessing = false;
  let isPaymentSuccess = false;
  let paymentError;
  let cardNumber;
  let payerName;

  const processCardPayment = async () => {
    // avoid processing duplicates
    if (isPaymentProcessing) return;

    isPaymentProcessing = true;

    // confirm payment with card
    const result = await stripe.confirmCardPayment(paymentData.clientSecret, {
      payment_method: {
        card: cardNumber,
        billing_details: {
          name: payerName,
        },
      },
    });

    if (result.error) {
      // payment failed, notify user
      paymentError = result.error;
    } else {
      // payment succeeded, redirect to "thank you" page and approve payment on backend
      const options = get(optionsStore);

      const res = await options.approveCardPayment(sessionId);

      if (res.ok) {
        isPaymentSuccess = true;

        if (successUrl) {
          setTimeout(() => (location.href = successUrl), redirectDelayTimeMs);
        }
      } else {
        paymentError = res.statusText;
        isPaymentSuccess = false;

        if (errorUrl) {
          setTimeout(() => (location.href = errorUrl), redirectDelayTimeMs);
        }
        console.error(
          "There was an error during the payment",
          res.status,
          res.statusText,
        );
      }
    }

    isPaymentProcessing = false;
  };
</script>

<div>
  <div class="card-modal {isCardModalVisible ? 'card-modal--visible' : ''}">
    <div class="card-modal__content">
      <button on:click={onCloseModal} class="card-modal__close-icon">X</button>
      {#if isCardModalVisible}
        {#if loadDataError}
          <div class="card-modal__body">
            <div class="card-modal__title card-modal__title--error">Error</div>
            <div class="card-modal__icon">
              <ErrorIcon />
            </div>
            <div class="card-modal__message">
              There was an issue with loading data for this payment
            </div>
            <button on:click={onCloseModal} class="card-modal__btn"
              >Close</button
            >
          </div>
        {:else if !stripe}
          <p>Loading...</p>
        {:else if isPaymentSuccess}
          <div class="card-modal__body">
            <div class="card-modal__title">Done!</div>
            <div class="card-modal__icon">
              <SuccessIcon />
            </div>
            <div class="card-modal__message">
              Payment was successful! Redirecting...
            </div>
            <button on:click={onCloseModal} class="card-modal__btn"
              >Close</button
            >
          </div>
        {:else}
          <div class="card-modal__title">Pay with Card</div>
          {#if paymentError}
            <div class="card-modal__message card-modal__message--error">
              {paymentError.message ?? "There was an error during the payment"}
            </div>
          {/if}
          <Elements {stripe}>
            <form on:submit|preventDefault={processCardPayment}>
              <input
                name="name"
                bind:value={payerName}
                placeholder="Your name"
                disabled={isPaymentProcessing}
              />
              <CardNumber
                bind:element={cardNumber}
                classes={{ base: "input" }}
              />

              <div class="row">
                <CardExpiry classes={{ base: "input" }} />
                <CardCvc classes={{ base: "input" }} />
              </div>

              <button disabled={isPaymentProcessing} class="card-modal__btn">
                {#if isPaymentProcessing}
                  Processing...
                {:else}
                  Pay
                {/if}
              </button>
            </form>
          </Elements>
        {/if}
      {/if}
      <div class="card-modal__footer">
        <span>Powered by</span>
        <div class="footer-svg">
          <FooterIcon />
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @import "../../styles/modal.styles.scss";
</style>
