<svg
  class="position-top-left"
  width="568"
  height="900"
  viewBox="0 0 568 900"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M527.868 402.644C473.293 363.932 375.981 362.63 330.166 378.612C328.746 379.085 328.036 380.506 328.272 381.926C328.509 383.347 329.693 384.412 331.232 384.412C331.232 384.412 332.297 384.412 332.771 384.412C389.003 384.412 435.765 401.46 461.218 431.411C474.477 447.038 480.277 464.795 477.436 481.369C468.321 534.879 388.767 585.902 247.298 628.757C137.319 662.023 27.34 679.544 6.38597 682.74C3.54475 683.214 1.53223 685.581 1.53223 688.423V742.998C1.53223 744.655 2.24253 746.312 3.54476 747.378C4.61021 748.325 5.91244 748.799 7.33304 748.799C7.56981 748.799 7.92496 748.799 8.28012 748.799C45.5711 742.998 177.333 720.623 304.122 679.189C479.094 621.891 567.882 554.294 568 478.054C568 447.038 554.623 421.703 528.105 402.88L527.868 402.644Z"
    fill="#E9E9FB"
  />
  <path
    d="M527.864 604.381C472.816 648.656 306.013 755.912 -51.8624 796.755C-54.822 797.11 -57.0713 799.596 -57.0713 802.437V857.841C-57.0713 859.498 -56.361 861.037 -55.1771 862.103C-54.1117 863.05 -52.8095 863.642 -51.3889 863.642C-51.2705 863.642 -51.0337 863.642 -50.9153 863.642C-6.52129 859.972 387.106 821.26 536.033 612.312C537.69 609.945 537.454 606.748 535.323 604.617C533.192 602.605 530.114 602.486 527.746 604.262L527.864 604.381Z"
    fill="#E9E9FB"
  />
  <path
    d="M349.571 793.071C311.333 814.972 163.471 888.371 -133.673 910.153C-136.632 910.39 -139 912.876 -139 915.954V974.199C-139 975.738 -138.408 977.277 -137.224 978.342C-136.159 979.408 -134.738 980 -133.318 980C-102.656 979.408 171.403 969.819 354.78 800.293C356.437 798.754 356.674 796.268 355.254 794.374C353.951 792.479 351.465 792.006 349.453 793.071H349.571Z"
    fill="#E9E9FB"
  />
  <path
    d="M-99.1046 496.671C-65.2467 520.703 -14.8151 530.411 29.4606 530.411C56.4521 530.411 81.3128 526.859 98.5969 520.703C100.017 520.23 100.728 518.809 100.491 517.388C100.254 515.968 99.0704 514.902 97.5314 514.902C97.5314 514.902 96.466 514.902 95.9924 514.902C39.76 514.902 -7.00174 497.855 -32.4543 467.904C-45.7133 452.277 -51.5142 434.519 -48.6729 417.946C-39.5574 364.436 39.9967 313.413 181.466 270.558C291.326 237.292 401.423 219.771 422.377 216.574C425.219 216.101 427.231 213.733 427.231 210.892V156.317C427.231 154.66 426.521 153.002 425.219 151.937C423.916 150.871 422.259 150.398 420.602 150.634C383.311 156.435 251.549 178.81 124.76 220.244C-50.0935 277.542 -139 345.258 -139 421.497C-139 452.514 -125.623 477.848 -99.1046 496.671Z"
    fill="#E9E9FB"
  />
  <path
    d="M-102.535 295.786C-101.233 295.786 -100.049 295.313 -98.9838 294.484C-43.9352 250.208 122.868 142.953 480.743 102.11C483.703 101.755 485.833 99.2688 485.833 96.4276V41.0238C485.833 39.3664 485.123 37.8274 483.939 36.762C482.755 35.6965 481.216 35.1046 479.677 35.3414C435.283 39.0113 41.6565 77.7229 -107.271 286.789C-108.928 289.157 -108.691 292.353 -106.442 294.484C-105.258 295.55 -103.956 296.142 -102.535 296.142V295.786Z"
    fill="#E9E9FB"
  />
  <path
    d="M562.075 -80.9408C531.414 -80.3488 257.355 -70.7597 73.9776 98.7663C72.3202 100.305 72.0835 102.791 73.5041 104.686C74.3328 105.869 75.7534 106.461 77.0556 106.461C77.7659 106.461 78.5946 106.224 79.3049 105.869C117.543 83.9683 265.405 10.5701 562.549 -11.2125C565.508 -11.4493 567.876 -13.9354 567.876 -17.0133V-75.1399C567.876 -76.6789 567.284 -78.2179 566.1 -79.2834C565.035 -80.3488 563.733 -81.0591 562.075 -80.9408Z"
    fill="#E9E9FB"
  />
</svg>

<style>
  .position-top-left {
    position: absolute;
    top: 0;
    left: -100px;
    height: 100%;
    width: 100%;
  }
</style>
