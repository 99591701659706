<!-- TODO: this is a mock component to be shown on demo, after the demo it'll be decided if we keep it or not -->
<script>
  import Fa from "svelte-fa";
  import { faSearch } from "@fortawesome/free-solid-svg-icons";
  import FooterIcon from "../icons/FooterIcon.svelte";

  const RAMP_LINK = process.env.RAMP_LINK;

  export let isOtherPaymentsModalVisible = false;
  export let onCloseModal;
  export let onSelectPlatform;
  export let onSearchBank;
</script>

<div>
  <div class="modal {isOtherPaymentsModalVisible ? 'modal--visible' : ''}">
    <div class="modal__content">
      <!-- Close button -->
      <button on:click={onCloseModal} class="modal__close-icon">X</button>

      {#if isOtherPaymentsModalVisible}
        <div class="modal__body">
          <div class="modal__title">Choose another method</div>

          <!-- Currency selection buttons -->
          <button on:click={() => onSelectPlatform("quid")} class="modal__btn"
            >Quid</button
          >
          <button on:click={() => onSelectPlatform("toonie")} class="modal__btn"
            >Toonie</button
          >

          <!-- Bank search input -->
          <div class="modal__search">
            <span class="modal__search-icon">
              <Fa icon={faSearch} />
            </span>
            <input
              type="text"
              placeholder="Search for your Bank"
              class="modal__search-input"
              on:input={onSearchBank}
            />
          </div>

          <!-- Message for unsupported banks -->
          <div class="modal__message">
            If your bank is not supported, open an account on
            <a href={RAMP_LINK} target="_blank" class="modal__link">esRAMP</a>
          </div>
        </div>
      {/if}

      <div class="modal__footer">
        <span>Powered by</span>
        <div class="footer-svg">
          <FooterIcon />
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @import "../../styles/modal.styles.scss";
</style>
