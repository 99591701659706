import { BigNumber, ethers } from "ethers";
import ERC20TokenABI from "./abi/ERC20Token.json";
import { AbstractWeb3Service } from "./abstractWebService.js";

/**
 * Wallet Service for basic usage like: get balance, transfer money etc.
 */
export class WalletService extends AbstractWeb3Service {
  /**
   * Constructor
   * @param web3Provider
   */
  constructor(web3Provider) {
    super(web3Provider);
  }

  /**
   * Transfer amount of token to receiver address (native currency)
   * @param receiverAddress
   * @param amount
   */
  async transferNative(receiverAddress, amount) {
    const signer = this.web3Provider.getSigner();

    let errorCode = null;
    let hasError = false;
    let txHash = null;

    try {
      const transactionResponse = await signer.sendTransaction({
        to: receiverAddress,
        value: amount,
      });
      txHash = transactionResponse.hash;
      await this.web3Provider.waitForTransaction(txHash);
    } catch (err) {
      errorCode = "ACTION_REJECTED";
      hasError = true;
    }

    return { error: hasError, hash: txHash, codeName: errorCode };
  }

  /**
   * Transfer amount of token to receiver address (erc20 token by contractAddress)
   * @param receiverAddress
   * @param amount
   * @param contractAddress
   */
  async transferErc20(receiverAddress, amount, contractAddress) {
    const contract = this.getContract(contractAddress);

    let errorCode = null;
    let hasError = false;
    let txHash = null;

    try {
      const transactionResponse = await contract.transfer(
        receiverAddress,
        amount,
      );
      txHash = transactionResponse.hash;
      await this.web3Provider.waitForTransaction(txHash);
    } catch (err) {
      errorCode = "ACTION_REJECTED";
      hasError = true;
    }

    return { error: hasError, hash: txHash, codeName: errorCode };
  }

  /**
   * Get current balance of token by contract address
   * @param walletAddress
   * @param contractAddress
   */
  async balanceOfErc20(walletAddress, contractAddress) {
    const contract = this.getContract(contractAddress);
    const response = await contract.balanceOf(walletAddress);

    return BigNumber.from(response);
  }

  /**
   * Get current balance (Native currency)
   */
  async balanceOfNative() {
    const signer = this.web3Provider.getSigner();
    const response = await signer.getBalance();

    return BigNumber.from(response);
  }

  /**
   * Get contract object by address
   * @param contractAddress
   * @private
   */
  getContract(contractAddress) {
    return new ethers.Contract(
      contractAddress,
      ERC20TokenABI,
      this.web3Provider.getSigner(),
    );
  }
}
