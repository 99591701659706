<script>
  import { loadStripe } from "@stripe/stripe-js";
  import { onMount } from "svelte";
  import { Elements } from "svelte-stripe";
  import { get, readable } from "svelte/store";
  import packageJson from "../package.json";
  import CardPaymentModal from "./components/modals/CardPaymentModal.svelte";
  import LogoIcon from "./components/icons/LogoIcon.svelte";
  import {
    checkInvalidOptions,
    formatAmountToDisplay,
    PaymentProcessingSteps,
  } from "./shared/utils/utils.svelte";
  import {
    optionsStore,
    paymentErrorsStore,
    isTransactionCompletedStore,
    transactionProcessingStepStore,
  } from "./store";
  import UserIcon from "./components/icons/UserIcon.svelte";
  import FooterIcon from "./components/icons/FooterIcon.svelte";
  import {
    createAuthorization,
    deleteAuthorization,
    handleFixedCryptoTransfer,
  } from "./features/web3/webAuth.js";
  import ErrorModal from "./components/modals/ErrorModal.svelte";
  import {
    setError,
    formatNetworkName,
    getProviderNameFromPaymentType,
    Providers,
  } from "./shared/utils/utils.svelte";
  import LeftSideBackground from "./components/icons/LeftSideBackground.svelte";
  import TrxStatus from "./components/TrxStatus.svelte";
  import { formatUnits, parseUnits } from "ethers/lib/utils.js";

  const options = get(optionsStore);
  const paymentSessionIdUrlKey = "orderId";
  const PUBLIC_STRIPE_KEY = process.env.PUBLIC_STRIPE_KEY ?? "";
  const ENV = process.env.ENVIRONMENT;
  const DOC_LINK = process.env.DOCUMENTATION_LINK;
  const version = readable(packageJson.version);

  // validating options object provided in the render method
  let loadDataError = checkInvalidOptions(options);

  let paymentDataBySessionId; // used to retrieve the payment data from the session id
  let paymentData; // used for testing the sdk purposes
  let isCardModalVisible = false;
  let isErrorModalVisible = false;
  let isPageLoading = true;
  let isPaymentProcessing;
  let isTransactionCompleted = false;
  let stripe;
  let paymentError;
  let minStreamAmount;
  let minDepositInSeconds;
  let isStreamPaymentType;
  let isFixedPaymentType;
  let streamManagerAddress;
  let gelatoFee;
  let transactionProcessingStep;

  let shouldShowNoButton = false;
  let isPayWithStrfiButtonVisible = false;
  let isStreamWithStrfiButtonVisible = false;

  paymentErrorsStore.subscribe((error) => {
    paymentError = error;
    if (error) isErrorModalVisible = true;
  });

  isTransactionCompletedStore.subscribe((isTrxCompleted) => {
    isTransactionCompleted = isTrxCompleted;
  });

  transactionProcessingStepStore.subscribe((trxProcessingStep) => {
    transactionProcessingStep = trxProcessingStep;
    isPaymentProcessing = !!trxProcessingStep;
  });

  onMount(async () => {
    stripe = await loadStripe(PUBLIC_STRIPE_KEY);

    const urlParams = new URLSearchParams(location.search);
    const paymentSessionIdFromUrl = urlParams.get(paymentSessionIdUrlKey);

    if (!paymentSessionIdFromUrl) {
      setError(new Error("NO_PAYMENT_SESSION_ID"));
      isPageLoading = false;
    } else {
      try {
        paymentDataBySessionId = await options.fetchPaymentDataBySessionId(
          paymentSessionIdFromUrl,
        );

        if (
          !paymentDataBySessionId.paymentSessionId &&
          paymentDataBySessionId.error
        ) {
          setError(new Error(paymentDataBySessionId.error));
          isPageLoading = false;
          return;
        }

        isStreamPaymentType =
          paymentDataBySessionId.cryptoPaymentType === "STREAM_AMOUNT";
        isFixedPaymentType =
          paymentDataBySessionId.cryptoPaymentType === "FIXED_AMOUNT";

        isPayWithStrfiButtonVisible =
          options.renderPayWithStrfiButton &&
          paymentDataBySessionId.providers.includes(Providers.CRYPTO);
        isStreamWithStrfiButtonVisible =
          options.renderStreamWithStrfiButton &&
          paymentDataBySessionId.providers.includes(Providers.CRYPTO);

        shouldShowNoButton =
          !isPayWithStrfiButtonVisible && !isStreamWithStrfiButtonVisible;

        if (isStreamPaymentType) {
          const decimals = paymentDataBySessionId.decimals;
          const amount = paymentDataBySessionId.amount;
          const parsedAmount = parseUnits(amount, decimals);

          const contractsPerNetwork = await options.getContractsPerNetwork({
            network: paymentDataBySessionId.cryptoNetwork,
          });

          minDepositInSeconds = contractsPerNetwork.minDepositInSeconds;

          minStreamAmount = formatUnits(
            parsedAmount.mul(minDepositInSeconds),
            decimals,
          );

          streamManagerAddress = contractsPerNetwork.smartContracts[0]?.address;
          gelatoFee = contractsPerNetwork.gelatoFee;

          if (!streamManagerAddress) {
            setError(new Error("MISSING_STREAM_MANAGER_ADDRESS"));
          }
        }
        isPageLoading = false;
      } catch (e) {
        setError(new Error("Unexpected_Error"));
        options.failurePaymentCallback(e);
        loadDataError = true;
        isPageLoading = false;
        console.warn("There was an issue with loading data for this payment");
      }
    }
  });

  const ensurePaymentDataExist = async (selectedPaymentType) => {
    // TODO: refactor this to be used both for lump sum both for stream payments
    if (!paymentDataBySessionId) {
      paymentData = await options.createPaymentSession();
    }

    if (
      paymentData?.paymentSessionId ||
      paymentDataBySessionId?.paymentSessionId
    ) {
      const paymentProvider =
        getProviderNameFromPaymentType(selectedPaymentType);

      try {
        const { transactionResponse } = await createAuthorization({
          tokenAddress: paymentDataBySessionId.cryptoTokenAddress,
          receiverAddress: paymentDataBySessionId.cryptoWalletAddress,
          maxFlowRate: paymentDataBySessionId.cryptoMaxFlowRate,
          maxDuration: paymentDataBySessionId.cryptoMaxDuration,
          maxAmount: paymentDataBySessionId.cryptoMaxAmount,
          decimals: paymentDataBySessionId.decimals,
          sessionId: paymentDataBySessionId.paymentSessionId,
          paymentProvider: paymentProvider,
          network: paymentDataBySessionId.cryptoNetwork,
          paymentType: paymentDataBySessionId?.cryptoPaymentType,
          onTransactionCreated: options.initiatePayment,
          streamAmount: paymentDataBySessionId?.amount,
          isCryptoNative: paymentDataBySessionId.isCryptoNative,
          streamManagerAddress,
          gelatoFee,
          minDepositInSeconds,
        });

        // TODO: merge with the error handling on fixed and create a function which handles the error
        if (transactionResponse) {
          if (transactionResponse?.error) {
            setError(new Error(transactionResponse.codeName));

            // update the payment session with the failure
            await options.updatePayment(
              paymentDataBySessionId?.paymentSessionId ||
                paymentData?.paymentSessionId,
              transactionResponse.codeName,
            );
          } else {
            isTransactionCompletedStore.set(true);
          }
        } else {
          setError(new Error("Unexpected_Error"));
        }
      } catch (error) {
        setError(error);
        options.failurePaymentCallback(error);
        console.error("There was an issue creating auth for this session");
      }

      transactionProcessingStepStore.set(null);
    } else {
      setError(new Error("NO_PAYMENT_SESSION_ID"));
    }
  };

  const onPaymentButtonClick = async (selectedPaymentType) => {
    try {
      switch (selectedPaymentType) {
        case "fixed":
          try {
            const { transferResponse } = await handleFixedCryptoTransfer({
              isCryptoNative: paymentDataBySessionId.isCryptoNative,
              tokenAddress: paymentDataBySessionId.cryptoTokenAddress,
              receiverAddress: paymentDataBySessionId.cryptoWalletAddress,
              decimals: paymentDataBySessionId.cryptoDecimals,
              amountToTransfer: paymentDataBySessionId.cryptoMaxAmount, // max amount because it is the amount parsed in wei from BE
              network: paymentDataBySessionId.cryptoNetwork,
              sessionId: paymentDataBySessionId.paymentSessionId,
              maxFlowRate: paymentDataBySessionId.cryptoMaxFlowRate,
              maxDuration: paymentDataBySessionId.cryptoMaxDuration,
              paymentType: paymentDataBySessionId?.cryptoPaymentType,
              onTransactionCreated: options.initiatePayment,
            });

            if (transferResponse) {
              // TODO: merge with the error handling on stream and create a function which handles the error
              if (transferResponse?.error) {
                setError(new Error(transferResponse.codeName));

                // update the payment session with the failure
                await options.updatePayment(
                  paymentDataBySessionId?.paymentSessionId ||
                    paymentData?.paymentSessionId,
                  transferResponse.codeName,
                );
              } else {
                isTransactionCompletedStore.set(true);
              }
            } else {
              setError(new Error("Unexpected_Error"));
            }
          } catch (error) {
            setError(error);
            options.failurePaymentCallback(error);
            console.error("There was an issue creating auth for this session");
          }

          transactionProcessingStepStore.set(null);
          break;
        case "stream":
          await ensurePaymentDataExist(selectedPaymentType);
          break;
        case "card":
          // TODO: review this with ensurePaymentDataExist function
          if (await ensurePaymentDataExist(selectedPaymentType)) {
            if (paymentData.clientSecret && paymentData.stripePaymentIntentId) {
              loadDataError = false;
              isCardModalVisible = true;
            } else {
              loadDataError = true;
              console.warn(
                "Error on loading payment data: missing clientSecret or paymentIntentId",
              );
            }
          }
          break;
        default:
          break;
      }
    } catch (error) {
      options.genericErrorCallback && options.genericErrorCallback(error);
      loadDataError = true;
      console.warn(
        `There was an issue with loading data for this payment: ${error}`,
      );
    }
  };

  const onCloseModal = () => {
    isCardModalVisible = false;
    isErrorModalVisible = false;
    isPaymentProcessing = false;

    const paymentError = get(paymentErrorsStore);
    options.onModalClose(paymentError);
  };
</script>

{#if isPageLoading}
  <div class="loader-container">
    <div class="loader"></div>
  </div>
{:else}
  <div class="main">
    <!-- LEFT SIDE -->
    <div class="left-side">
      <LeftSideBackground />
      <div class="card">
        <div class="icon">
          <UserIcon />
        </div>
        <h1 class="title">
          {formatAmountToDisplay(
            paymentDataBySessionId?.amount,
            paymentDataBySessionId?.currency,
            isStreamPaymentType,
          )}
        </h1>
        <h3 class="subtitle">Streaming amount</h3>
        <div class="row">
          <p class="label">Reason:</p>
          <p class="value">
            {paymentDataBySessionId?.reason ?? ""}
          </p>
        </div>
        <div class="row">
          <p class="label">Merchant:</p>
          <p class="value">NetStream</p>
        </div>
        {#if isStreamPaymentType}
          <div class="row">
            <p class="label">Min. amount to start the stream:</p>
            <p class="value">
              {formatAmountToDisplay(
                minStreamAmount,
                paymentDataBySessionId?.currency,
              )}
            </p>
          </div>
        {/if}
        <div class="row">
          <p class="label">Streaming amount:</p>
          <p class="value">
            <span class="total">
              {formatAmountToDisplay(
                paymentDataBySessionId?.amount,
                paymentDataBySessionId?.currency,
                isStreamPaymentType,
              )}
            </span>
          </p>
        </div>
        <div class="row">
          <p class="label">Network:</p>
          <p class="value">
            {paymentDataBySessionId?.cryptoNetwork
              ? formatNetworkName(paymentDataBySessionId?.cryptoNetwork)
              : ""}
          </p>
        </div>
      </div>
    </div>

    <!-- RIGHT SIDE -->
    <div class="right-side">
      <div class="svg-title">
        <LogoIcon width="200" height="70" viewBox="0 0 240 70" />
      </div>

      <!-- Hide session info card on mobile if transaction data are displayed -->
      {#if !isTransactionCompleted && (paymentDataBySessionId?.status === "CREATED" || (paymentDataBySessionId?.status === "FAILED" && paymentDataBySessionId?.canUserRetryPayment))}
        <div class="card card-mobile">
          <div class="icon">
            <UserIcon />
          </div>
          <h1 class="title">
            {formatAmountToDisplay(
              paymentDataBySessionId?.amount,
              paymentDataBySessionId?.currency,
              isStreamPaymentType,
            )}
          </h1>
          <h3 class="subtitle">Streaming amount</h3>
          <div class="row">
            <p class="label">Reason:</p>
            <p class="value">{paymentDataBySessionId?.reason ?? ""}</p>
          </div>
          <div class="row">
            <p class="label">Merchant:</p>
            <p class="value">NetStream</p>
          </div>
          {#if isStreamPaymentType}
            <div class="row">
              <p class="label">Min. amount to start the stream:</p>
              <p class="value">
                {formatAmountToDisplay(
                  minStreamAmount,
                  paymentDataBySessionId?.currency,
                )}
              </p>
            </div>
          {/if}
          <div class="row">
            <p class="label">Streaming amount:</p>
            <p class="value">
              <span class="total">
                {formatAmountToDisplay(
                  paymentDataBySessionId?.amount,
                  paymentDataBySessionId?.currency,
                  isStreamPaymentType,
                )}
              </span>
            </p>
          </div>
          <div class="row">
            <p class="label">Network:</p>
            <p class="value">
              {paymentDataBySessionId?.cryptoNetwork
                ? formatNetworkName(paymentDataBySessionId?.cryptoNetwork)
                : ""}
            </p>
          </div>
        </div>
      {/if}

      <div
        class={isTransactionCompleted ||
        (paymentDataBySessionId?.status &&
          paymentDataBySessionId?.status !== "CREATED" &&
          !paymentDataBySessionId?.canUserRetryPayment)
          ? "column-trx"
          : "column"}
      >
        <ErrorModal {isErrorModalVisible} error={paymentError} {onCloseModal} />

        <!-- Show trx status instead of buttons if the payment advanced to a new status different from the initial (CREATED) -->
        {#if isTransactionCompleted || (paymentDataBySessionId?.status === "FAILED" && !paymentDataBySessionId?.canUserRetryPayment) || (paymentDataBySessionId?.status !== "CREATED" && paymentDataBySessionId?.status !== "FAILED")}
          <TrxStatus sessionId={paymentDataBySessionId?.paymentSessionId} />
        {:else if isPaymentProcessing}
          <h2 class="subtitle" style="white-space: pre-line">
            {transactionProcessingStep}
          </h2>
          {#if transactionProcessingStep === PaymentProcessingSteps.SEND_AUTH}
            <div style="margin-top: 1rem">
              <a
                href={DOC_LINK ? `${DOC_LINK}how-it-works/` : ""}
                target="_blank">learn more</a
              >
            </div>
          {/if}
          <div class="three-dot-loader-container">
            <div class="three-dot-loader" style="margin-top: 10px"></div>
          </div>
        {:else if shouldShowNoButton}
          <h2 class="subtitle">No payment methods available</h2>
        {:else}
          <h2 class="subtitle">Pay with</h2>

          <!-- DO NOT RELEASE THIS BUTTON, USE IT ONLY FOR TESTING PURPOSES -->
          {#if ENV === "dev"}
            <button
              on:click={() =>
                deleteAuthorization({
                  tokenAddress: paymentDataBySessionId.cryptoTokenAddress,
                  receiverAddress: paymentDataBySessionId.cryptoWalletAddress,
                  network: paymentDataBySessionId.cryptoNetwork,
                  streamManagerAddress,
                })}
              class="primary-btn"
            >
              <span class="primary-btn__text">Delete auth</span>
            </button>
          {/if}

          {#if isPayWithStrfiButtonVisible && isFixedPaymentType}
            <button
              on:click={() => onPaymentButtonClick("fixed")}
              class="primary-btn"
            >
              Pay with Web3 Wallet
            </button>
          {/if}

          {#if isStreamWithStrfiButtonVisible && isStreamPaymentType}
            <button
              on:click={() => onPaymentButtonClick("stream")}
              class="primary-btn"
            >
              Your Web3 Wallet
            </button>
          {/if}

          {#if stripe && options.renderPayWithCardButton}
            <Elements {stripe}>
              <button
                on:click={() => onPaymentButtonClick("card")}
                class="primary-btn primary-btn--card"
              >
                Pay with card
              </button>
              {#if isCardModalVisible}
                <CardPaymentModal
                  {isCardModalVisible}
                  {loadDataError}
                  {stripe}
                  {paymentData}
                  sessionId={paymentDataBySessionId?.paymentSessionId}
                  {onCloseModal}
                  successUrl={paymentDataBySessionId?.successUrl}
                  errorUrl={paymentDataBySessionId?.errorUrl}
                />
              {/if}
            </Elements>
          {/if}
        {/if}

        <div class="footer-mobile">
          <div class="footer--row">
            <a href={DOC_LINK} target="_blank" class="footer--link"
              >How it works?</a
            >
          </div>
          <div class="footer--row">
            <span>Powered by</span>
            <div class="footer-svg">
              <FooterIcon />
            </div>
          </div>
          <div class="footer--row">
            <span>Version: {$version}</span>
          </div>
        </div>
      </div>

      <div class="footer">
        <div class="footer--row">
          <a href={DOC_LINK} target="_blank" class="footer--link"
            >How it works?</a
          >
        </div>
        <div class="footer--row">
          <span>Powered by</span>
          <div class="footer-svg">
            <FooterIcon />
          </div>
        </div>
        <div class="footer--row">
          <span>Version: {$version}</span>
        </div>
      </div>
    </div>
  </div>
{/if}

<style lang="scss">
  @import "./styles/styles.scss";
</style>
